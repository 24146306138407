<template>
  <section v-for="(promo, i) in promotions" :key="i">
    <!-- S : Promotion 달성 여부 1개 짜리 -->
    <div class="px-4 py-6" :style="{ backgroundColor : promo.bg_color }" v-if="promo.type === '1'">
      <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
        {{ promo.title }}
        <button class="" :class="{ togg1: promo.toggle_status }" @click='promo.toggle_status = !promo.toggle_status'>
          <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
        </button>
      </h4>
      <div class="xl:mt-6 lg:mt-6 mt-8" v-show="promo.toggle_status">
        <!-- S : 50BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">{{ promo.col_name1 }}</span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block "></span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col1" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col2 == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span> </span>&nbsp;Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 50BV -->
        <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
          The promotion achievement status is updated on a deadline basis.<br>
          If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
          If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
          <!--      프로모션 달성 현황은 마감 시간 기준으로 갱신됩니다.<br>
                    프로모션 기간 내 본인 반품 시 대상에서 제외됩니다.<br>
                    프로모션 마감 후 달성자 발표 전까지 1건이라도 반품이
                    확인 된 경우 프로모션 달성은 무효가 되며 여행 달성자에서
                    제외 됩니다.-->
        </div>
      </div>
    </div>
    <!-- E : Promotion 달성 여부 1개 짜리 -->
    <!-- S : Promotion 달성 여부 2개 짜리 -->
    <div class="px-4 py-6" :style="{ backgroundColor : promo.bg_color }" v-if="promo.type === '2'">
      <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
        {{ promo.title }}
        <button class="" :class="{ togg1: promo.toggle_status }" @click='promo.toggle_status = !promo.toggle_status'>
          <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
        </button>
      </h4>
      <div class="xl:mt-6 lg:mt-6 mt-8" v-show="promo.toggle_status">
        <!-- S : 120BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">120BV Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">{{ promo.col_name1 }}</span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name1 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block "></span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col1" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col3 == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span> </span>Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 120BV -->
        <!-- S : 240BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">{{ promo.col_name2 }} Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">240BV</span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name2 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block "></span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col2" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col4 == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span> </span>Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 240BV -->
        <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
          The promotion achievement status is updated on a deadline basis.<br>
          If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
          If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
          <!--      프로모션 달성 현황은 마감 시간 기준으로 갱신됩니다.<br>
                    프로모션 기간 내 본인 반품 시 대상에서 제외됩니다.<br>
                    프로모션 마감 후 달성자 발표 전까지 1건이라도 반품이
                    확인 된 경우 프로모션 달성은 무효가 되며 여행 달성자에서
                    제외 됩니다.-->
        </div>
      </div>
    </div>
    <!-- E : Promotion 달성 여부 2개 짜리 -->
    <!-- S : Promotion 달성 여부 1개 컬럼에 세 가지 이상 달성 종류 -->
    <div class="px-4 py-6" :style="{ backgroundColor : promo.bg_color }" v-if="promo.type === '3'">
      <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
        {{ promo.title }}
        <button class="" :class="{ togg1: promo.toggle_status }" @click='promo.toggle_status = !promo.toggle_status'>
          <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
        </button>
      </h4>
      <div class="xl:mt-6 lg:mt-6 mt-8" v-show="promo.toggle_status">
        <!-- S : 50BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">{{ promo.col_name1 }}</span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block "></span><input type="text" class="text-right xl:w-24 lg:w-24 w-20 p-1" v-model="promo.col1" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col2 !== ''" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" >{{ promo.col2 }} Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 50BV -->
        <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
          The promotion achievement status is updated on a deadline basis.<br>
          If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
          If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
        </div>
      </div>
    </div>
    <!-- E : Promotion 달성 여부 1개 컬럼에 세 가지 이상 달성 종류 -->
    <!-- S : Promotion 달성 여부 4개 짜리 -->
    <div class="px-4 py-6" :style="{ backgroundColor : promo.bg_color }" v-if="promo.type === '4'">
      <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
        {{ promo.title }}
        <button class="" :class="{ togg1: promo.toggle_status }" @click='promo.toggle_status = !promo.toggle_status'>
          <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
        </button>
      </h4>
      <div class="xl:mt-6 lg:mt-6 mt-8" v-show="promo.toggle_status">
        <!-- S : 50BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">{{ promo.col_name1 }}</span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name3 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block ">{{ promo.col_name3 }}</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col1" readonly />
            </div>
            <span class="flex items-center text-white">&nbsp;&#47;&nbsp;</span>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name4 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block ">{{ promo.col_name4 }}</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col2" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col4 == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span>{{ promo.col3 }} </span>&nbsp;Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 50BV -->
        <!-- S : 500BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">{{ promo.col_name2 }}</span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name5 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block ">{{ promo.col_name5 }}</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col5" readonly />
            </div>
            <span class="flex items-center text-white">&nbsp;&#47;&nbsp;</span>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name6 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block ">{{ promo.col_name6 }}</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col6" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col8 == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span>{{ promo.col7 }} </span>&nbsp;Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 500BV -->
        <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
          The promotion achievement status is updated on a deadline basis.<br>
          If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
          If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
          <!--      프로모션 달성 현황은 마감 시간 기준으로 갱신됩니다.<br>
                    프로모션 기간 내 본인 반품 시 대상에서 제외됩니다.<br>
                    프로모션 마감 후 달성자 발표 전까지 1건이라도 반품이
                    확인 된 경우 프로모션 달성은 무효가 되며 여행 달성자에서
                    제외 됩니다.-->
        </div>
      </div>
    </div>
    <!-- E : Promotion 달성 여부 4개 짜리 -->
    <!-- S : Promotion 좌우 표기 있고, 달성 여부 2개 컬럼에 세 가지 이상 달성 종류 -->
    <div class="px-4 py-6" :style="{ backgroundColor : promo.bg_color }" v-if="promo.type === '5'">
      <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
        {{ promo.title }}
        <button class="" :class="{ togg1: promo.toggle_status }" @click='promo.toggle_status = !promo.toggle_status'>
          <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
        </button>
      </h4>
      <div class="xl:mt-6 lg:mt-6 mt-8" v-show="promo.toggle_status">
        <!-- S : 50BV -->
        <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
          <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
            <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center"></span></div>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name1 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block ">{{ promo.col_name1 }}</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col2" readonly />
            </div>
            <span class="flex items-center text-white">&nbsp;&#47;&nbsp;</span>
            <div class="flex gap-1 items-center">
              <span class="text-white xl:inline-block lg:inline-block hidden">{{ promo.col_name2 }} Achievement</span>
              <span class="text-white xl:hidden lg:hidden inline-block ">{{ promo.col_name2 }}</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo.col2" readonly />
            </div>
          </div>
          <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
            <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
            <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
            <div v-if="promo.col3 !== ''" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span>{{ promo.col3 }} </span>&nbsp;Achieved</div>
            <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
          </div>
        </div>
        <!-- E : 50BV -->
        <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
          The promotion achievement status is updated on a deadline basis.<br>
          If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
          If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
        </div>
      </div>
    </div>
    <!-- E : Promotion 좌우 표기 있고, 달성 여부 2개 컬럼에 세 가지 이상 달성 종류 -->
  </section>
</template>

<script>
export default {
  name: "promotions",
  computed: {

  },
  data: () => ({
    promotions : [],
  }),
  beforeCreate() {
    axios.post(window.Laravel.back_url+'/api/promotion_index',{
      // bz_cd : window.Laravel.bz_cd // my, sg, id
    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
      if (response.data.ret == '200') {
        this.promotions = response.data;
      }
    });
  }
}
</script>

<style scoped>
</style>
