<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div class="flex justify-between">
                    <h1
                      class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">
                      {{ i18n.myoffice.Orderview }}</h1>
                    <!--                    <span class="text-sm leading-4 mt-4">전체보기
                                            <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-4 w-4 mt-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                              <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                            </svg>
                                        </span>-->
                  </div>

                  <div
                    class="flex w-full justify-between py-2 px-3 bg-slate-50 hover:bg-white cursor-pointer border rounded-md searchForm"
                    v-on:click="showMessage = !showMessage">
                    <div class="basis-1/2">Date Designation</div>
                    <div class="basis-1/2 flex justify-end" @click="showHideContents($event)">
                      <MinusSmIcon class="h-6 w-6 minus-contents-body contents-active-none" aria-hidden="true"/>
                      <PlusSmIcon class="h-6 w-6 plus-contents-body" aria-hidden="true"/>
                    </div>
                  </div>
                  <div class="w-full p-2 rounded bg-white lg:bg-slate-50 contents-body contents-active"
                       v-if="showMessage">
                    <div>
                      <p class="p-0 text-sm font-semibold lg:p-2">
                        <span class="text-md">{{ i18n.myoffice.myoSearchForm.selectDate }}</span>
                        <span
                          class="text-blue-600 text-md font-medium block border border-slate-300 bg-white p-2 text-center lg:border-0 lg:inline-block lg:px-2 lg:bg-transparent lg:p-0 mt-2 lg:mt-0 xl:mt-0">
                        {{ this.s_date }} ~ {{ this.e_date }}
                        </span>
                      </p>
                      <div class="w-full py-2 grid grid-cols-2 lg:grid-cols-5 gap-2 lg:p-2.5">
                        <!--                            <button class="w-1/5 border bg-white hover:bg-black hover:text-white rounded-xl">{{ i18n.search.allday }}</button>
                                                    <button class="w-1/5 border bg-white hover:bg-black hover:text-white rounded-xl">{{ i18n.search.before1month }}</button>
                                                    <button class="w-1/5 border bg-black text-white hover:bg-black hover:text-white rounded-xl">{{ i18n.search.before3month }}</button>
                                                    <button class="w-1/5 border bg-white hover:bg-black hover:text-white rounded-xl">{{ i18n.search.before6month }}</button>
                                                    <button class="w-1/5 border bg-white hover:bg-black hover:text-white rounded-xl">{{ i18n.search.before12month }}</button>-->
                        <button
                          @click="selectDate(dates)"
                          class="border bg-white hover:bg-black hover:text-white rounded-md p-2 border-slate-300 text-sm lg:text-md text-black"
                          :class="{isSelected: dates.selected }"
                          v-for="dates in dateSelect" :key="dates.code">
                          {{ dates.label }}
                        </button>
                      </div>
                    </div>
                    <div>
                      <p class="p-2 text-sm font-semibold">Order Type</p>
                      <div class="w-full py-2 grid grid-cols-2 lg:grid-cols-4 gap-2 lg:p-2.5">
                        <!--                              <button class="w-1/5 border bg-white hover:bg-black hover:text-white rounded-xl">Normal</button>
                                                      <button class="w-1/5 border bg-white hover:bg-black hover:text-white rounded-xl">Autoship</button>-->
                        <button
                          @click="selectKindCd(kindCd)"
                          class="border bg-white hover:bg-black hover:text-white rounded-md p-2 border-slate-300  text-sm lg:text-md text-black"
                          :class="{isSelected: kindCd.selected }"
                          v-for="kindCd in kindCds" :key="kindCd.code">
                          {{ kindCd.label }}
                        </button>
                      </div>
                    </div>
                    <div>
                      <p class="p-2 text-sm lg:text-md font-semibold">Order Classification</p>
                      <div class="w-full py-2 grid grid-cols-2 lg:grid-cols-4 gap-2 lg:p-2.5">
                        <button
                          @click="selectOrdKind(ordKind)"
                          class="border bg-white hover:bg-black hover:text-white rounded-md p-2 border-slate-300  text-sm lg:text-md text-black"
                          :class="{isSelected: ordKind.selected }"
                          v-for="ordKind in ordKinds" :key="ordKind.code">
                          {{ ordKind.label }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative overflow-x-auto  sm:rounded-lg mt-4">
                <span>There are <span class="text-blue-400 font-semibold">{{ this.total_count }}</span> orders in total.</span>
                <!-- 모바일 사이즈 -->
                <div
                  class="relative overflow-x-auto shadow-md sm:rounded-lg w-full shadow-md mt-1.5 lg:border lg:border-slate-300">
                  <ul
                    class="text-sm text-gray-700 uppercase bg-white dark:bg-gray-700 dark:text-gray-400 border-b font-semibold bg-slate-50 hidden lx:flex lg:flex items-center">
                    <li class="px-6 py-3 basis-1/7 text-center">{{ i18n.myoffice.Order.orderDate }}</li>
                    <li class="px-6 py-3 basis-1/7 text-center">{{ i18n.myoffice.Order.ordno }}</li>
                    <li class="px-6 py-3 basis-1/7 text-center">COUNTRY</li>
                    <li class="px-6 py-3 basis-1/7 text-center">{{ i18n.myoffice.Order.amount }}</li>
                    <li class="px-6 py-3 basis-1/7 text-center">{{ i18n.myoffice.Order.bv }}</li>
                    <li class="px-6 py-3 basis-1/7 text-center">{{ i18n.myoffice.Order.type }}</li>
                    <li class="px-6 py-3 basis-1/7 text-center">{{ i18n.myoffice.Order.ordKind }}</li>
                    <li class="px-6 py-3 basis-1/7 text-center">Submit</li>
                  </ul>
                  <ul v-for="(Value,index) in orderList"
                      class="rounded-md lg:rounded-none xl:rounded-none border dark:bg-gray-200 dark:border-gray-100 font-thin block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent">
                    <li
                      class="flex justify-between items-center px-2 py-3 border-black border-b lg:hidden xl:hidden mb-2">
                      <div>
                        <span class="text-md font-semibold">{{ Value.ord_date2 }}</span>&nbsp;
                        <span class="text-sm font-normal">&#40;{{ Value.ord_no }}&#41;</span>
                      </div>
                      <a class="text-xs font-semibold" @click="view_order(Value.href)">View Details &#62;</a>
                    </li>
                    <!-- <th class="px-6 py-3 whitespace-nowrap">No</th> -->
                    <!-- <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ total_count - ((page-1)*20 + index) }}</td> -->
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md">
                      <div class="px-2 py-1 lg:px-3 lg:py-3 font-normal w-full lg:text-center hidden lg:block xl:block">
                        {{ Value.ord_date2 }}
                      </div>
                    </li>
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md">
                      <div
                        class="px-2 py-1 lg:px-3 lg:py-3 font-semibold w-full lg:text-center hidden lg:block xl:block">
                        <a class="text-blue-400 underline underline-offset-1 cursor-pointer"
                           @click="view_order(Value.href)">{{ Value.ord_no }}</a>
                      </div>
                    </li>
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md">
                      <div class="px-2 py-1 lg:px-3 lg:py-3 font-normal w-full lg:text-center hidden lg:block xl:block">{{ Value.bz_cd }}
                        <p v-if="bz_cds[laravel.bz_cd] !== Value.bz_cd" class="text-xs text-[#bcc3d1]">(Multi Code)</p>
                      </div>
                    </li>
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md flex lg:block xl:block">
                      <div class="px-3 py-1 lg:px-3 lg:py-3 block w-full lg:hidden font-medium">
                        {{ i18n.myoffice.Order.amount }}
                      </div>
                      <div class="px-3 py-1 lg:px-3 lg:py-3 font-normal w-full lg:text-center">
                        {{ currency[Value.bz_cd] }} {{ formatPrice(Value.ord_amt, 2) }}
                      </div>
                    </li>
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md flex lg:block xl:block">
                      <div class="px-3 py-1 lg:px-3 lg:py-3 block w-full lg:hidden font-medium">
                        {{ i18n.myoffice.Order.bv }}
                      </div>
                      <div class="px-3 py-1 lg:px-3 lg:py-3 font-normal w-full lg:text-center">
                        {{ formatPrice(Value.ord_pv, 1) }}
                      </div>
                    </li>
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md flex lg:block xl:block">
                      <div class="px-3 py-1 lg:px-3 lg:py-3 block w-full lg:hidden font-medium">
                        {{ i18n.myoffice.Order.type }}
                      </div>
                      <div class="px-3 py-1 lg:px-3 lg:py-3 font-normal w-full lg:text-center">{{
                          Value.kind_name
                        }}
                      </div>
                    </li>
                    <li class="w-full lg:basis-1/8 text-md lg:text-md xl:text-md flex lg:block xl:block">
                      <div class="px-3 py-1 lg:px-3 lg:py-3 block w-full lg:hidden font-medium">
                        {{ i18n.myoffice.Order.ordKind }}
                      </div>
                      <div class="px-3 py-1 lg:px-3 lg:py-3 font-normal w-full lg:text-center"
                           :class="{'text-[#ff0000]' : Value.ord_kind == 0 , 'text-blue-500'  : Value.ord_kind != 0}">
                        {{ Value.ord_kinds }}
                      </div>
                    </li>
                    <li class="w-full lg:basis-2/8 text-md lg:text-md xl:text-md">
                      <!--                      <div class="px-2 py-1 lg:px-6 lg:py-3 block w-full lg:hidden">Submit</div>-->
                      <div class="px-3 py-3 xl:py-1 xl:px-1 lg:py-1 lg:px-1 font-normal w-full text-center" v-if="bz_cds[laravel.bz_cd] === Value.bz_cd">
                        <button
                          class="px-2 py-3 lg:py-2 text-sm border border-black bg-white lg:text-white lg:bg-black rounded w-full"
                          v-show="Value.r_types.r_type == 'C'"
                          v-on:click="ViewModal(Value.ord_no,Value.r_types.r_type)">Cancel/Change
                        </button>
                        <button
                          class="px-2 py-3 lg:py-2 text-sm border border-black bg-white lg:text-white lg:bg-black rounded w-full"
                          v-show="Value.r_types.r_type == 'R'"
                          v-on:click="ViewModal(Value.ord_no,Value.r_types.r_type)">Exchange/Return
                        </button>
                        <span class="text-slate-400 font-normal text-xs" v-show="Value.r_types.r_type.length > '2'">{{
                            Value.r_types.r_type
                          }} <br>{{ Value.r_types.status }}</span>
                      </div>
                      <div v-else></div>
                    </li>
                  </ul>

                  <div
                    class="rounded-md lg:rounded-none xl:rounded-none border dark:bg-gray-200 dark:border-gray-100 font-thin block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent"
                    v-if="total_count == 0">
                    <div class="w-full text-center px-2 py-3 text-black">{{ this.list_msg }}</div>
                  </div>
                </div>

              </div><!-- // -->

              <div v-if="total_count < 1" class="justify-center">
                <!-- 주문내역 없을때 -->
                <div class="justify-center mt-4 bg-slate-50 py-5 px-2 border border-slate-300 rounded text-center">
                  <div class="w-full flex justify-center">
                    <div class="w-24 text-center">
                      <TruckIcon class="h-6 sm:h-20 w-24 text-zinc-600" aria-hidden="true"/>
                    </div>
                  </div>
                  <p class="text-md lg:text-xl justify-center text-center">There is no order
                    history{{ this.message_month }}.</p>
                  <button class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-4/5 md:w-1/4 rounded"
                          @click="clickOption">To set a search option
                  </button>
                </div>
              </div>

              <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4"
                   v-if="total_count > 0">
                <v-pagination
                  v-model="page"
                  :pages=total_page
                  :range-size="1"
                  active-color="#DCEDFF">
                </v-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
  <modalView v-if="showRModal" @close-modal="showRModal = false" :harf="false">
    <MyViewOrderRefund :ord_no="ord_no" :r_type="r_type" ref="MyViewOrderRefund"></MyViewOrderRefund>
  </modalView>
  <modalView v-if="showModal" @close-modal="showModal = false" :harf="false">
    <MyViewOrderCancel :ord_no="ord_no" :r_type="r_type" ref="MyViewOrderCancel"></MyViewOrderCancel>
  </modalView>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {MinusSmIcon, PlusSmIcon, TruckIcon} from '@heroicons/vue/outline';
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import VPagination from "@hennge/vue3-pagination";
import moment from 'moment';
import modalView from "../../modalView.vue";
import MyViewOrderCancel from '../../myoffice/Order/MyViewOrderCancel.vue';
import MyViewOrderRefund from '../../myoffice/Order/MyViewOrderRefund.vue';

export default {
  name: "mypage_order_list",
  components: {
    VueSimpleAlert,
    MinusSmIcon,
    PlusSmIcon,
    TruckIcon,
    MypageTop,
    SideMenu,
    VPagination,
    moment,
    modalView,
    MyViewOrderCancel,
    MyViewOrderRefund,
    r_type: '',
  },
  data: () => ({
    //show hide
    showMessage: false,
    showMessage2: false,

    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    products: [],
    orderList: [],
    no_search_messsage: '',
    isLoggedIn: false,
    max_qty: 99,
    showModal: false,
    showRModal: false,
    VPagination: [1],
    ordKinds: [
      {code: "1", label: "Order", selected: false},
      {code: "0", label: "Cancel", selected: false},
      {code: "3", label: "Exchange/Return Order", selected: false},
    ],
    kindCds: [
      {code: "1", label: "Normal", selected: false},
      {code: "6", label: "Autoship", selected: false},
    ],
    dateSelect: [
      {code: "0", label: i18n.search.allday, selected: false},
      {code: "1", label: i18n.search.before1month, selected: false},
      {code: "3", label: i18n.search.before3month, selected: true},
      {code: "6", label: i18n.search.before6month, selected: false},
      {code: "12", label: i18n.search.before12month, selected: false},
    ],
    // 멀티코드 주문 내역을 위한 처리
    currency : {MYS: "RM", SGP: "S$", IDN: "Rp"},
    bz_cds   : {my: "MYS", sg: "SGP", id: "IDN"},
  }),

  created() {
    this.s_date = moment().subtract(3, 'months').format('DD-MM-YYYY');
    this.e_date = moment().format('DD-MM-YYYY');
    this.message_month = " for the last 3 months";
    this.getOrderList();

  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods: {
    getOrderList() {
      this.loading = true;
      this.userid = window.Laravel.user;
      axios.post(window.Laravel.back_url + '/api/getMyOrderList', {
        s_date: this.s_date,
        e_date: this.e_date,
        page: this.page,
        sort: this.sort,
        ord_kind: this.ord_kind ?? '9',
        bz_cd: this.bz_cd,
        userkind_type: this.userkind_type,
        kind_cd: this.kind_cd,
        rcpt_yns: this.rcpt_yns,
        keyword1: this.keyword1,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        //console.log(response.data);
        this.orderList = response.data.list.data;
        this.total_count = response.data.total_count;
        this.total_page = parseInt(this.total_count / 20) + parseInt(this.total_count % 20 > 0 ? 1 : 0);
        this.loading = false;
        if (this.total_count == 0) {
          //VueSimpleAlert.alert("There are no search results","","",{});
          this.list_msg = "No data";
        }

      });

    },
    list_url(link) {
      location.href = "/product/list/" + link;
    },

    // 장바구니 담긴 상품수
    getCountCartItems() {
      axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
        bz_cd: window.Laravel.bz_cd
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
        .then(response => {
          this.$store.commit("setCartCount", response.data);
        });
    },
    addCart(product) {
      axios.post(process.env.MIX_BACK_URL + '/api/add_cart', {
        item: product.id,
        qty: product.qty,
        client_ip: window.Laravel.clientIp,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
        .then(response => {
          if (response.data.msg) {
            if (response.data.result == 'Y') {

              VueSimpleAlert.alert("", "", "", {
                background: 'transparent',
                timer: 1000,
                imageHeight: 300,
                imageUrl: '/images/putin_cart_icon_us.png',
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: false
              }).then((result) => {
                if (result) {

                  this.getCountCartItems()
                  // axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
                  //   bz_cd: window.Laravel.bz_cd
                  // }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                  //   .then(response => {
                  //     this.countCartItems = response.data;
                  //     this.$store.commit("setCartCount", this.countCartItems);
                  //   });
                }
              });
            } else if (response.data.result == 'D') {

              VueSimpleAlert.confirm(response.data.msg, "", "", {allowOutsideClick: false}).then((result) => {
                if (result) {

                  // 기존 카테고리 삭제 후,
                  axios.post(window.Laravel.back_url + '/api/registerByChangingCart',
                    {
                      item: product.id,
                      qty: product.qty,
                      client_ip: window.Laravel.clientIp,
                    }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}
                  ).then(response => {
                    if (response.data.result == 'Y') {
                      VueSimpleAlert.alert("", "", "", {
                        background: 'transparent',
                        timer: 1000,
                        imageHeight: 300,
                        imageUrl: '/images/putin_cart_icon_us.png',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false
                      }).then((result) => {
                        if (result) {
                        }
                      }).catch((err) => {
                        throw new Error(`Problem handling something: ${err}.`);
                      });
                      this.loading = false;
                      this.getCountCartItems()

                      // axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
                      //   bz_cd: window.Laravel.bz_cd
                      // }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                      //   .then(response => {
                      //     this.countCartItems = response.data;
                      //     this.$store.commit("setCartCount", this.countCartItems);
                      //   });
                    }
                  });
                }
              });
            } else {
              VueSimpleAlert.alert(response.data.msg, "", "", {}).then((result) => {
                if (result) {
                  //location.replace("/MyforinerUpload");
                }
              });

              //alert(response.data.msg);
            }
          }
          if (response.data.result == 'Y') {
            //window.location.reload()
          }
        });
    },
    decrement(product) {
      product.qty = this.formatPrice(product.qty, 0);

      if (product.qty > 1) {
        product.qty--;
      }
    },
    increment(product) {
      product.qty = this.formatPrice(product.qty, 0);
      if (product.qty < this.max_qty) {
        product.qty++;
      }
    },
    changeQty(product) {
      product.qty = this.formatPrice(product.qty, 0);
      if (product.qty < 1) {
        this.count = 1;
      }
      if (product.qty > this.max_qty) {
        product.qty = this.max_qty;
      }
    },
    formatPrice(value, digit) {
      // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
      value = isNaN(value) ? 0 : value;
      let val = (value / 1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    view_order(order_no) {
      order_no = order_no.replace("/MyOrderView/", "");
      location.href = "/Mypage/Order/" + order_no;
    },
    set_ord_kind(ord_kind) {
      this.ord_kind = ord_kind;
      this.getOrderList();
    },
    selectOrdKind(item) {
      if (item.selected == true) {
        this.ord_kind = '9';
        item.selected = !item.selected;
        this.getOrderList();
      } else {
        this.ord_kind = item.code;
        for (var i = 0; i < this.ordKinds.length; i++) {
          this.ordKinds[i].selected = false;
        }
        item.selected = !item.selected;
        this.getOrderList();
      }
    },
    selectKindCd(item) {
      if (item.selected == true) {
        this.kind_cd = '';
        item.selected = !item.selected;
        this.getOrderList();
      } else {
        this.kind_cd = item.code;
        for (var i = 0; i < this.kindCds.length; i++) {
          this.kindCds[i].selected = false;
        }
        item.selected = !item.selected;
        this.getOrderList();
      }
    },
    selectDate(item) {
      if (item.code == "0") {
        this.s_date = "";
        this.message_month = "";
      } else {
        if (item.code == "1") {
          this.message_month = " for the last one month";
        } else {
          this.message_month = " for the last " + item.code + " months";
        }
        this.s_date = moment().subtract(item.code, 'months').format('DD-MM-YYYY');
      }
      for (var i = 0; i < this.dateSelect.length; i++) {
        this.dateSelect[i].selected = false;
      }
      item.selected = !item.selected;
      this.getOrderList();
    },
    ViewModal(ord_no, r_type) {
      this.ord_no = ord_no;
      this.r_type = r_type;
      if (r_type == "C") {
        this.showModal = true;
      }
      if (r_type == "R" || r_type == "R2") {
        this.showRModal = true;
      }
    },

    showHideContents: function (e) {
      if ($(".contents-body").hasClass("contents-active")) {
        $(".contents-body").removeClass('contents-active');
        $(".contents-body").addClass('contents-active-none');
        $(".plus-contents-body").removeClass('contents-active-none');
        $(".minus-contents-body").addClass('contents-active-none');
      } else {
        $(".contents-body").removeClass('contents-active-none');
        $(".contents-body").addClass('contents-active');
        $(".minus-contents-body").removeClass('contents-active-none');
        $(".plus-contents-body").addClass('contents-active-none');
      }
    },
    clickOption() {
      if ($(".contents-body").hasClass("contents-active")) {
        window.scrollTo(0, 0);
      } else {
        $(".searchForm").click();
      }

    }


  }
}
</script>

<style scoped>
.isSelected {
  background: #000;
  color: #fff
}

.contents-active {
  display: block;
}

.contents-active-none {
  display: none;
}
</style>
