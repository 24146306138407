<template>
  <dl class="myo_search_list">
    <dt>{{ i18n.myoffice.MyMember.standardMember }}</dt>
    <dd>
      <div class="myo_search_box">
        <input type="hidden" v-model="search_id" id="search_id" name="search_id" >
        <input type="text" class="myo_search_input" v-model="sid_name" id="sid_name" />
        <button type="button" class="myo_search_btn sid-wrap" @click="find_user"><i class="fas fa-search"></i></button>

      </div>
    </dd>
    <div id="ufind-bg" style="display:none" >
      <div id="ufind-wrap" >
        <i class="fal fa-times-square btnCloseLayer" @click="ufindhidden()" aria-hidden="true"></i>
        <div id="user-list">
          <table class="result_find">
            <tbody>
                <tr class="bg-slate-300 text-center">
                  <th class="py-2 border-r border-slate-500 text-sm" style="word-break: break-word;">{{ i18n.myoffice.MemberID }}</th>
                  <th class="py-2 border-r border-slate-500 text-sm" style="word-break: break-word;">{{ i18n.myoffice.memberUpChange.member }}</th>
                  <th class="py-2 text-sm">{{ i18n.myoffice.Commission.Position }}</th>
                </tr>
                <tr v-for="Value in mem_list" v-on:click='sel_user(Value.sid,Value.userid,Value.username)'>
                  <td class='f_user text-sm'>{{ Value.userid }}</td>
                  <td class="text-sm">{{ Value.username }}</td>
                  <td class="text-sm">{{ Value.rankname }}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </dl>
</template>

<script>
import VueSimpleAlert from "vue3-simple-alert";

export default {
  name: "memberSearch",
  components : {
    VueSimpleAlert
  },
  data: () => ({
    i18n: window.i18n,
    sid_name : "",
    search_id : "",
    mem_list : [],
  }),
  props : {
    selectid  : "",
  },
  created : function (){
    if(this.selectid != "" && this.selectid != undefined){
      axios.post(window.Laravel.back_url+'/api/Member/Find',{
        find:this.selectid
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if(response.data.length > 0){
          this.search_id = response.data[0].sid;
          this.sid_name = response.data[0].userid+' ('+response.data[0].username+')';
        }
      });
    }

  },
  methods : {
    find_user(user_callback){
      user_callback = typeof user_callback !== 'undefined' ? user_callback : "";

      var now = new Date();
      var find = this.sid_name;

      if(find.length <= 1) {
        alert(InputSearchData); /*검색 내용을 입력해 주세요!*/
        return false;
      }

      axios.post(window.Laravel.back_url+'/api/Member/Find',{
        find:this.sid_name
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.mem_list = [];
        response.data.forEach((value, index) => {
          this.mem_list.push({
            index: index,
            userid: value.userid,
            sid : value.sid,
            id: value.id,
            username: value.username,
            rankname: value.rankname,

          });
          $("#ufind-bg").show();
        });
        if(response.data.length == 0){
          VueSimpleAlert.alert("There are no search results","","",{});
          this.sid_name = "";
        }
      });
    },
    sel_user(sid,userid,username){
      this.search_id = sid;
      this.sid_name = userid+' ('+username+')';
      $("#ufind-bg").hide();

    },
    ufindhidden(){
      this.mem_list = [];
      $("#ufind-bg").hide();
    }
  }
}
</script>

<style >
#ufind-bg { position: absolute; background-color:white;/* width:300px;*/ width: calc(100% - 45px); max-width:320px;  z-index: 10; max-height:200px; overflow-y: auto; overflow-x:hidden; border: 1px solid #d0d0d0; }
#ufind-wrap { padding: 30px 10px 20px 10px; width:100%; position: relative; }
#user-list { margin: 10px auto 0;  }
#ufind-bg .btnCloseLayer { position: absolute; top:5px; right:47%; font-size:25px; cursor:pointer;}
#user-list .sel_user { cursor: pointer;}
#user-list table.result_find {
  border-collapse: collapse;
  text-align: left;
  line-height: 1.5;
  border-left: 1px solid #ccc;
  margin: 20px 10px;
}

#user-list table.result_find thead th {
  /*padding: 10px;*/
  margin-top:5px;
  font-weight: bold;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 2px solid #c00;
  background: #dcdcd1;
}
#user-list table.result_find tbody th {
  width: 150px;
  padding: 10px;
  font-weight: bold;
  vertical-align: top;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #f1f2f7;
}
#user-list table.result_find td {
  width: 350px;
  padding:10px 5px;
  vertical-align: top;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size:14px;
}

#search_member-wrap {
  display:block;
  border:1px solid #e0e0e0;
  /*width:calc(100% - 20px);*/
  margin:15px 0 10px 0;
  padding:10px;
  position:relative;
  -webkit-overflow-scrolling:touch;
  padding-top:16px;
}

</style>
