<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">Search Address</h2>
        <p @click=" close_address_search " class="cursor-pointer p-2">X</p>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        <div class="grid lg:grid-cols-3 lg:grid-rows-1 grid-cols-1 gap-3">
            <vSelect name="state" ref="d_state" :options="provinsi_list" :reduce="value => value.address" v-model='provinsi' :clearable="false"
                     @option:selected="onChangeAddress('2', provinsi)" class="w-full text-gray-600 text-sm" ></vSelect>
            <vSelect name="state" ref="d_state" :options="kabupaten_list" :reduce="value => value.address" v-model='kota_kabupaten' :clearable="false"
                     @option:selected="onChangeAddress('3', kota_kabupaten)" class="w-full text-gray-600 text-sm" ></vSelect>
            <vSelect name="state" ref="d_state" :options="kecamatan_list" v-model="kecamatan_obj" return-object :clearable="false"
                     @option:selected="onChangeAddress('4', '')" class="w-full text-gray-600 text-sm"></vSelect>
        </div>
        <div class="w-full p-4 overflow-auto	">
            <div class="w-full text-gray-300 py-2 border-b">After making your selection, please click on the address below.</div>
            <table class="w-full" v-if="post_label">
                <tbody>
                    <tr class="p-2 border border-gray-200 w-full hover:bg-slate-100	cursor-pointer " >
                        <td class="p-2" @click="onChangePost(post_value)">{{ post_label }}</td>
                    </tr>
                </tbody>
            </table>
            <label class="block mb-3 text-sm font-semibold text-gray-500" v-else>{{ post_label }}</label>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";

export default {
    name: "addressSearch",
    components : {
        // address_book,
        vSelect,
    },
    props:{
        openlayer: Boolean
    },
    data : () => ({
        provinsi_list     : [],
        kabupaten_list    : [],
        kecamatan_list    : [],
        post_label        : "",
        post_value        : "",
        provinsi          : '-- * PROVINSI --',
        kota_kabupaten    : '-- * KABUPATEN --',
        kecamatan_obj     : '-- * KECAMATAN --',
        kecamatan_distrik : '',
        post              : '',
        city_code         : '', // postcode가 없는 곳도 있어서 postcode 대신 unique 값으로 사용 : ord_deli.r_county
        tariff            : '', // 배송료
        free_bv           : ''  // 무료배송 기준 (미달 시, 배송료 받음(tariff 적용))
    }),
    watch : {
        kecamatan_obj : function(){
            this.kecamatan_distrik = this.kecamatan_obj.address;
            this.post              = this.kecamatan_obj.postcode;
            this.city_code         = this.kecamatan_obj.city_code;
            this.tariff            = this.kecamatan_obj.tariff;
            this.free_bv           = this.kecamatan_obj.free_bv;
        }
    },
    created : function () {
        this.onChangeAddress('1', '');
    },
    methods : {
        onChangeAddress(address_type, bind_addr) {
            axios.post(process.env.MIX_BACK_URL+'/api/getIdSearchList',{
                address_type : address_type,
                bind_addr    : bind_addr,
                bz_cd        : "id"
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.post_label = "";
                    if(response.data.ret == '-1') {
                        this.post_label = "Please select your address";
                    }else if(response.data.address.length < 1) {
                        this.post_label = "No addresses found";
                    }
                    this.getAddressList(response.data.address, address_type);
                })
                .catch(e => {
                });

        },
        getAddressList(data, addressType = '1') {
            if(addressType === '1') {
                this.provinsi_list = [{label : '-- * PROVINSI --', address : ''}];
                data.forEach((value, index) => {
                    this.provinsi_list.push({
                        label      : value.address,
                        address    : value.address
                    });
                });
            } else if(addressType === '2') {
                this.kabupaten_list = [{label : '-- * KABUPATEN --', address : ''}];
                data.forEach((value, index) => {
                    this.kabupaten_list.push({
                        label      : value.address,
                        address    : value.address
                    });
                });
            } else if(addressType === '3') {
                this.kecamatan_list = [{label : '-- * KECAMATAN --', address : ''}];
                data.forEach((value, index) => {
                    this.kecamatan_list.push({
                        label      : value.address,
                        address    : value.address,
                        postcode   : value.postcode,
                        city_code  : value.city_code,
                        tariff     : value.tariff,
                        free_bv    : value.free_bv
                    });
                });
            } else if(addressType === '4') {
                let address1    = [this.provinsi, this.kota_kabupaten, this.kecamatan_distrik].join(', ');
                this.post_label = "[" + this.post + "] " + address1;
                this.post_value = {'kecamatan_distrik' : this.kecamatan_distrik, 'kota_kabupaten' : this.kota_kabupaten, 'provinsi' : this.provinsi,
                    'postcode' : (!this.post) ? '' : this.post, 'city_code' : this.city_code, 'tariff' : this.tariff, 'free_bv' : this.free_bv};
            }
        },
        onChangePost(post_select){
            this.$emit("set_address", post_select);
        },
        onChangeStreet(){

        },
        close_address_search(){
            // this.openlayer = false;
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>
.vs--open { display: block; z-index:999 !important; } /* 모바일 버전 깨짐 보완 */
</style>
