<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>
    <div class="w-full lg:flex lg:items-center lg:justify-between bg-white mt-2 lg:bg-slate-50 xl:bg-slate-50 lg:py-4 xl:py-4 lg:px-4 xl:px-4">

        <!-- 메인 페이지에서만 노출 -->
        <div v-show="main == 1"  class="flex flex justify-start items-start flex-col space-y-4">
            <a href="/MyPersonInfo" class="flex">
              <div class="inline-block w-10 h-10 bg-[#323d48] text-white text-center text-xl leading-10 rounded-full">G</div>
              <div class="inline-block pl-2">
                  <p class="text-2xl p-0 m-0 leading-5 mb-2">{{ this.username }}</p>
                  <p class="p-0 m-0 leading-5">({{ this.user }})</p>
                  <p class="p-0 m-0 leading-5 xl:hidden lg:hidden block">My Current BV : <span>{{ bv }}</span>BV</p>
              </div>
            </a>
        </div>

        <div v-show="main != 1" class="hidden lg:flex lg:justify-start lg:items-start lg:flex-col lg:space-y-4 xl:flex xl:justify-start xl:items-start xl:flex-col xl:space-y-4">
          <a href="/MyPersonInfo" class="flex">
            <div class="inline-block w-10 h-10 bg-[#323d48] text-white text-center text-xl leading-10 rounded-full">G</div>
            <div class="inline-block pl-2">
              <p class="text-2xl p-0 m-0 leading-5 mb-2">{{ this.username }}</p>
              <p class="p-0 m-0 leading-5">({{ this.user }})</p>
            </div>
          </a>
        </div>

        <!-- 메인 페이지에서만 노출 -->
        <div v-show="main == 1" class="hidden lg:flex xl:flex justify-start items-start flex-col space-y-4">
            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">My Orders</p>
            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600 cursor-pointer" onclick="location.replace('/Mypage/Order');"><span class="text-lg font-semibold">{{ this.myorder_cnt }}</span> Orders</p>
        </div>
        <div v-show="main == 1" class="hidden lg:flex xl:flex justify-start items-start flex-col space-y-4">
          <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">My Current BV</p>
          <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600"><span class="text-lg font-semibold">{{ bv }}</span> BV</p>
        </div>
        <div v-show="main == 1" class="hidden lg:flex xl:flex justify-start items-start flex-col space-y-4">
          <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Hearts balance</p>
          <p class="w-48 lg:w-full dark:text-gray-300 xl:w-40 text-left text-sm leading-5 text-gray-600"><span class="text-lg font-semibold">{{ h_stamp }}</span> Hearts</p>
        </div>
        <div v-show="main == 1"  class="hidden lg:flex xl:flex justify-start items-start flex-col space-y-4">
            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">1:1 Inquiries</p>
            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                <button class="w-full py-1 border-2 rounded bg-white" onclick="location.replace('/Mypage/Inquiry');">Inquire</button>
            </p>
        </div>
      <!-- 지쿱 아카데미 - 임시 주석처리 20230622 -->
<!--        <div v-show="main == 1"  class="hidden lg:flex xl:flex justify-start items-start flex-col space-y-4">-->
<!--            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Online Academy</p>-->
<!--            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">-->
<!--                <button class="w-full py-1 border-2 rounded bg-white" onclick="location.replace('/MypageAcademy');">move</button>-->
<!--            </p>-->
<!--        </div>-->

<!--    <div v-show="main != 1" class="hidden lg:flex xl:flex lg:gap-1 xl:gap-1 mt-4 xl:mt-0 lg:mt-0 justify-end">-->
        <div v-show="main != 1" class="hidden lg:flex xl:flex mt-4 xl:mt-0 lg:mt-0 justify-end">
            <a href="/Mypage/Order" class="px-2 py-1.5 text-sm text-center relative"><span class="after:content-[''] after:absolute after:top-1/2 after:right-0 after:translate-y-[-50%] after:border-solid after:border after:h-5 after:border-slate-200">{{ i18n.myoffice.Orderview }}</span></a>
            <a href="/Mypage/Inquiry" class="px-2 py-1.5 text-sm text-center"><span class="">1:1 Inquiries</span></a>
<!--            <span class="after:content-['>'] last:after:content-[''] text-xs xl:tex-sm lg:text-sm">온라인 아카데미</span>-->
        </div>

        <!-- 메인 페이지에서만 노출 -->
        <div v-show="main == 1" class="lg:hidden xl:hidden flex items-center rounded-md border border-slate-300 mt-4">
          <a href="/Mypage/Order" class="px-1 py-1.5 text-xs basis-2/6 text-center relative"><span class="after:content-[''] after:absolute after:top-1/2 after:right-0 after:translate-y-[-50%] after:border-solid after:border after:h-5 after:border-gray-300">View<br>Order Delivery</span></a>
          <a href="/Mypage/Inquiry" class="px-1 py-1.5 text-xs basis-2/6 text-center relative"><span class="after:content-[''] after:absolute after:top-1/2 after:right-0 after:translate-y-[-50%] after:border-solid after:border after:h-5 after:border-gray-300">1:1<br>Inquires</span></a>
          <a href="/Mypage/Notice" class="px-1 py-1.5 text-xs basis-2/6 text-center"><span class="px-1 py-2">Announcement</span></a>
        </div>

    </div>
</template>

<script>
export default {
    name: "MypageTop",
    props:['laravel','main'],
    data: () => ({
      i18n: window.i18n,
      user : '',
      username : '',
      myorder_cnt : 0,
      bv : 0,
      h_stamp : 0,
    }),
    mounted() {
      this.getOrderList();
      if (this.laravel.isLoggedin) {
        this.isLoggedIn = true;
        this.user = this.laravel.user;
        this.username = this.laravel.username;
      }
    },
  methods:{
    getOrderList(){
        this.loading = true;
        this.userid = window.Laravel.user;
        axios.post(window.Laravel.back_url+'/api/getMyOrderList',{
          s_date : this.s_date,
          e_date : this.e_date,
          page : this.page,
          sort: this.sort,
          ord_kind: this.ord_kind ?? '1',
          bz_cd: this.bz_cd,
          userkind_type: this.userkind_type,
          kind_cd: this.kind_cd,
          rcpt_yns: this.rcpt_yns,
          keyword1 : this.keyword1,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
          this.myorder_cnt = response.data.total_count;
          this.bv = response.data.ord_pv;
          this.h_stamp = response.data.h_stamp;
        });

    },
  }
}
</script>

<style scoped>

</style>
