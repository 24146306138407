<template>
  <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true"  >
    <address_book :mypage_yn='mypage_yn' @close_address_book="close_address_book" />
  </modalView>
  <div class="w-full mb-auto lg:w-48 border-2 px-4 rounded divide-y bg-white">
    <div class="py-4">
      <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">{{ i18n.menu.MyOrder}}</div>
      <ul class="px-0.5">
        <li class="py-0.5 text-sm cursor-pointer"><a href="/Mypage/Order">{{ i18n.myoffice.Orderview2 }}</a></li>
        <!--                <li class="py-0.5 text-sm"><a href="">주문취소 내역</a></li>
                        <li class="py-0.5 text-sm"><a href="">교환/반품 내역</a></li>-->
        <li class="py-0.5 text-sm cursor-pointer"><a href="/Mypage/AddressList">{{ i18n.myoffice.ShippingManage }}</a></li>
      </ul>
    </div>
    <div class="py-4">
      <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Member Information</div>
      <ul class="px-0.5" >
        <li class="py-0.5 text-sm"  v-show="rank_cd != '00'"><a href="/MyofficeMain">{{ i18n.menu.gomyoffice}}</a></li>

        <li class="py-0.5 text-sm" v-show="rank_cd == 0"><a href="/Mypage/idpwchk">{{ i18n.menu.MyPersonInfo}}</a></li>
        <li class="py-0.5 text-sm" v-show="rank_cd == 0"><a href="/Mypage/userDel">{{ i18n.menu.MyOfficeUserDel }}</a></li>

        <li class="py-0.5 text-sm" v-show="rank_cd != 0"><a href="/MyPersonInfo">{{ i18n.menu.MyPersonInfo}}</a></li>
        <li class="py-0.5 text-sm" v-show="rank_cd != 0"><a href="/MyMemberWithdrawal">{{ i18n.menu.MyOfficeUserDel }}</a></li>

        <li class="py-0.5 text-sm"><a href="/Mypage/memberFind">{{ i18n.menu.MemberCheck }}</a></li>
      </ul>
    </div>
    <div class="py-4">
      <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">{{ i18n.menu.MyCommunity}}</div>
      <ul class="px-0.5">
        <li class="py-0.5 text-sm"><a href="/Mypage/Inquiry">{{ i18n.menu.MyInQuriy}}</a></li>
        <li class="py-0.5 text-sm"><a href="/Mypage/Notice">{{ i18n.menu.MyNotice}}</a></li>
        <li class="py-0.5 text-sm"><a href="/Mypage/FAQ">FAQ</a></li>
        <!--                <li class="py-0.5 text-sm"><a href="">{{ i18n.menu.MyPushMessages}}</a></li>-->
      </ul>
    </div>
    <div class="py-4">
      <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Heart</div>
      <ul class="px-0.5">
        <li class="py-0.5 text-sm"><a href="/Mypage/heartManager">heartManager</a></li>
      </ul>
    </div>
<!--    오토십 히든처리 20230616-->
<!--      <div class="py-4" v-if="Autoship">-->
<!--          <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">{{ i18n.menu.MyAutoshipHistory}}</div>-->
<!--          <ul class="px-0.5">-->
<!--              <li class="py-0.5 text-sm"><a href="/Mypage/AutoshipEdit">{{ i18n.menu.MyAutoshipEdit}}</a></li>-->
<!--              <li class="py-0.5 text-sm"><a href="/Mypage/AutoShipWithdrawal">{{ i18n.menu.MyAutoshipWithdrawal}}</a></li>-->
<!--              <li class="py-0.5 text-sm"><a href="/Mypage/heartManager">heartManager</a></li>-->
<!--          </ul>-->
<!--      </div>-->

  </div>
</template>

<script>
  import modalView from "../modalView";
  export default {
    name: "SideMenu",
    props:['laravel'],
    components : {
      modalView,
    },
    data: () => ({
      i18n: window.i18n,
      address_book_open:false,
      mypage_yn : 'Y',
      rank_cd:window.Laravel.rank_cd,
      // 오토십 여부 노출 여부 add by hskim :: 2023-07-24 오전 9:21
      // Autoship : true,
      Autoship : window.isAutoship,
    }),
    methods : {
      // 주소록 레이어 오픈
      close_address_book(){
        this.address_book_open = false;
      },
      open_address_book(){
        this.address_book_open = true;
      },
    }
  }
</script>

<style scoped>

</style>
