<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true">
        <address_book @get_info_address_book="get_info_address_book" @close_address_book="close_address_book"/>
    </modalView>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true">
        <address_search @set_address="set_address" @close_address_search="close_address_search"/>
    </modalView>

    <!--    <div>-->
    <!--      <address_book @close_address_book="close_address_book" @get_info_address_book="get_info_address_book" v-if="address_book_open" />-->
    <!--  </div>-->

    <section class="container mx-auto py-6">
        <div class="w-full text-center m-auto mb-12">
            <button @click="free_delivery_text = true" v-show="free_delivery_text == false"  class="w-[90%] lg:w-auto bg-gray-200 text-black px-10 py-2 rounded-xl">Periksa halaman ketentuan untuk pengiriman gratis</button>
            <button @click="free_delivery_text = false" v-show="free_delivery_text == true" class="w-[90%] lg:w-auto bg-gray-200 text-black px-10 py-2 rounded-xl">Tutup halaman ketentuan untuk pengiriman gratis</button>
        </div>
        <div class="cart-notice" v-show="free_delivery_text == true">
            ※ The number of items or selected items can be deleted.<br>
            <div style="color:#f75e5e;">※ Persyaratan untuk Ongkos Kirim Gratis<br>
                1) Pembelanjaan minimum 30BV : Jakarta, Bogor, Depok, Bekasi, Kotra Bandung, Kota Surakarta, Kota Semarang<br>
                2) Pembelanjaan minimum 40BV : Tangerang, Banten Kota, Kota Surabaya, Kota Sukabumi, Kota Tasikmalaya, Kota Tegal<br>
                3) Pembelanjaan minimum 70BV : Pulau Jawa (selain kota di atas)<br>
                4) Pembelanjaan minimum 80BV : Indramayu, Sukabumi<br>
                5) Pembelanjaan minimum 100BV : Kota Banjar, Bandung Barat, Garut, Sumedang, Kab. Tasikmalaya, Temanggung, Wonogiri, Wonosobo, Pacitan
                <br>
                6) Pembelanjaan minimum 140BV : Kota Batam, Kota Medan, Kota Pekanbaru, Kota Palangkaraya, Kota Pontianak, Pangandaran, Sumatera Selatan<br>
                7) Pembelanjaan minimum 160BV : Kota Tanjung Pinang, Kota Banjarmasin, Kota Banjarbaru<br>
                8) Pembelanjaan minimum 170BV : Kota Makassar<br>
                9) Pembelanjaan minimum 200BV : Kota Sorong, Kota Manado
            </div>
        </div>

        <div class="bg-white">
            <div v-show="false" class="flex justify-between pb-8 max-w-8xl lg:max-w-6xl mx-auto ">
                <button @click="link('/cart')"
                        class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-1/2 max-w-2xl">
                    <a>Cart({{ products.length }})</a></button>
                <button @click="link('/AsCart')"
                        class="bg-white font-semibold border hover:bg-gray-300 py-3 text-sm text-black uppercase w-1/2 max-w-2xl">
                    <a>AutoShip({{ other_products.length }})</a></button>
            </div>

            <div class="max-w-8xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="flex flex-wrap">
                    <div class="w-full sm:w-3/4 bg-white sm:px-10">

                        <div class="flex justify-between pb-8">
                            <h1 class="font-semibold text-2xl">Shopping Cart</h1>
                            <h2 class="font-semibold text-lg md:text-xl">{{ products.length }} Items</h2>
                        </div>
                        <div class="flex justify-end border-b pb-2 ">
                            <button class="mt-1 p-1 text-sm text-white bg-indigo-400 rounded" @click="removeSelect">Remove selected
                                items
                            </button>
                        </div>
                        <div class="mt-6 mb-5 hidden lg:flex">
                            <h3 class="font-semibold text-gray-600 text-xs uppercase w-full sm:w-3/6">Product Details</h3>
                            <h3 class="font-semibold text-center text-gray-600 text-xs uppercase w-1/3 sm:w-1/6 text-center">
                                Quantity</h3>
                            <h3 class="font-semibold text-center text-gray-600 text-xs uppercase w-1/3 sm:w-1/6 text-center">
                                Harga GCOOPER</h3>
                            <h3 class="font-semibold text-center text-gray-600 text-xs uppercase w-1/3 sm:w-1/6 text-center">
                                Harga Produk</h3>
                        </div>
                        <!--// 장바구니 제품 목록-->
                        <div v-for="(product, i) in products" :key="product.seq_pd" v-if="isEnterProduct"
                             class="sm:flex sm:items-center  px-2 py-5 border-b border-slate-200 last:border-b-0">
                            <span class="px-3">
                              <input type="checkbox" :name="product.seq_pd" :value="product.seq_pd"
                                     v-model="selectedItems">
                            </span>
                            <div class="flex w-full sm:w-1/2"> <!-- product -->
                                <a :href="'/product/item/'+product.seq_pd">
                                    <div class="relative w-24 aspect-w-1 aspect-h-1">
                                        <img class="w-24 h-24 max-w-none	"
                                             v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename"
                                             v-bind:alt=" product.images[0].filename " alt="">
                                        <div class="sold-out-box2" v-if="product.product.status == 2">
                                            <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out"/>
                                        </div>
                                        <div class="sold-out-box2" v-if="product.product.status == 3">
                                            <img src="/images/badge/us/pc_sold_out.png" alt="sold out"/>
                                        </div>
                                        <div class="coming_box" v-if="product.product.status == 4">
                                            <img src="/images/badge/us/pc_coming_soon.png" alt="sold out"/>
                                        </div>
                                        <div class="badge_box" v-if="product.badges.image_link != ''">
                                            <img :src="product.badges.image_link" alt="sold out"/>
                                        </div>
                                    </div>
                                </a>
                                <div class="flex flex-col justify-between ml-4 flex-grow relative">
                                    <a :href="'/product/item/'+product.seq_pd" class="block">
                                        <span class="font-bold text-base">{{ product.product.pdt_name }}</span>
                                        <div class="text-red-500 text-xs lg:text-sm" v-if="product.product.deli_date2"
                                             v-html="product.product.deli_date2"></div>
                                        <div class="text-right absolute bottom-0 right-0 z-[5]">
                                            <a href="#" v-on:click.prevent="removeItem($event,i)"
                                               class="font-semibold hover:text-red-500 text-gray-500 text-xs border border-slate-400 inline-block p-1">Remove</a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="flex w-full sm:w-1/2 pt-3 sm:pt-0">
                                <div class="flex justify-center w-1/3">
                                    <div
                                        class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md">
                                        <button @click="decrement($event,i)" class="py-2 sm:py-1 hover:text-gray-700">
                                            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                 class="text-zinc-600 text-blue-400">
                                                <g opacity="0.35">
                                                    <rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect>
                                                </g>
                                            </svg>
                                        </button>
                                        <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)"
                                               class="w-12 m-0 px-2 py-2 sm:py-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md"
                                               id="custom-input-number">
                                        <button @click="increment($event,i)" class="py-2 sm:py-1 hover:text-gray-700">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                 class="text-zinc-600 text-blue-400">
                                                <g opacity="0.35">
                                                    <rect x="5" width="2" height="12" fill="currentColor"></rect>
                                                    <rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)"
                                                          fill="currentColor"></rect>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-center w-1/3 font-semibold text-sm">
                                    <p class="text-sm lg:text-lg">Rp{{ formatPrice(product.prices.amt, 0) }}</p>
                                    <p class="text-cyan-400">{{ formatPrice(product.prices.pv, 1) }}BV</p>
                                </div>
                                <div class="text-center w-1/3 font-semibold text-sm">
                                    <p class="text-sm lg:text-lg">Rp{{ formatPrice(product.prices.amt * product.qty, 0) }}</p>
                                    <p class="text-cyan-400">{{ formatPrice(product.prices.pv * product.qty, 1) }}BV</p>
                                </div>
                            </div>

                        </div>
                        <!--장바구니 제품 목록 //-->
                        <div class="flex justify-end pt-2">
                            <button class="mt-1 p-1 text-sm text-white bg-indigo-400 rounded" @click="removeSelect">Remove selected
                                items
                            </button>
                        </div>

                        <div class="w-full text-center mb-12 pt-3 " v-if="isEnterShipping && isEnterProduct==false">
                            <button @click="isEnterProduct = !isEnterProduct" class="bg-indigo-400 text-white px-10 py-2 rounded-xl">
                                View Product Details
                            </button>
                        </div>
                        <div class="flex justify-between">
                            <a href="/public" v-on:click.prevent="prevUrl" class="flex font-semibold text-indigo-600 text-sm my-10">
                                <svg class="fill-current mr-2 text-indigo-600 w-4" viewBox="0 0 448 512">
                                    <path
                                        d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"/>
                                </svg>
                                Continue Shopping
                            </a>
                        </div>


                        <div class="w-full text-center mb-12">
                            <button v-if="!isEnterShipping"
                                    @click="isEnterShipping = !isEnterShipping, isEnterProduct = !isEnterProduct"
                                    class="bg-indigo-400 text-white px-10 py-2 rounded-xl">Enter shipping information
                            </button>
                            <button v-if="isEnterShipping" @click="isEnterShipping = !isEnterShipping"
                                    class="bg-gray-200 text-white px-10 py-2 rounded-xl">Close shipping information
                            </button>
                        </div>
                        <div class="mb-12" v-show="isEnterShipping">
                            <div class="flex justify-between border-b pb-8">
                                <h1 class="font-semibold text-2xl">Shipping Address</h1>
                            </div>
                            <form name="shipping_info" class="justify-center w-full mx-auto" method="post">
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="order_seq">
                                <input type="hidden" name="shipping_fee">
                                <input type="hidden" name="tax_fee">
                                <input type="hidden" name="pay_total_amt">
                                <input type="hidden" name="street" :value='this.shipping_info["d_street"]'>
                                <input type="hidden" name="postcode" :value='this.shipping_info["d_post"]'>
                                <input type="hidden" name="city" :value='this.shipping_info["d_city"]'>
                                <input type="hidden" name="state" :value='this.shipping_info["d_state"]'>
                                <div class="mt-10">

                                    <div class="space-x-0 sm:flex lg:space-x-4">
                                        <div class="w-full flex sm:w-1/4" v-show="false">
                                            <input name="order-info" id="order-info1" ref="order-info1" type="radio" class="mt-0.5" value="1"
                                                   @click="change_shipping('Same')" >
                                            <label for="order-info1" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Same as My information</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/3" v-show="!isOnlyPickup">
                                            <input name="order-info" id="order-info2" type="radio" class="mt-0.5" value="2"
                                                   @click="change_shipping('Enroll')" checked>
                                            <label for="order-info2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Alamat pengiriman lain</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/3" v-show="!isOnlyPickup">
                                            <input name="order-info" id="order-info3" type="radio" class="mt-0.5" value="3"
                                                   @click="change_shipping('Manage')">
                                            <label for="order-info3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Kelola alamat pengiriman</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/3" v-show="rank_cd !='00'">
                                            <input name="order-info" id="order-info4" type="radio" class="mt-0.5" value="4"
                                                   @click="change_shipping('Academi')">
                                            <label for="order-info4" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Delivery to GCOOPER Academy
                                            </label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4" v-show="false">
                                            <input name="order-info" id="order-info5" type="radio" class="mt-0.5" value="5"
                                                   @click="change_shipping('Pickup')">
                                            <label for="order-info5" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Pick-up
                                            </label>
                                        </div>
                                    </div>
                                    <div class="relative pt-3 xl:pt-6 z-30 hidden" v-show="is_academi_order == false && !isOnlyPickup">
                                        <div class="font-bold py-3">
                                            Academy for Performance
                                        </div>
                                        <p class="mb-2">Please select the academy where the purchase performance will be accumulated.</p>
                                        <vSelect name="state" id="center_list" ref="center_list" :options="center_list" placeholder="Select"
                                                 label="cnt_name" :reduce="value => value.cnt_cd" v-model='commition_cnt_cd'
                                                 :clearable="false" class="block w-full text-gray-600 text-sm"></vSelect>
                                    </div>

                                    <div class="relative pt-3 xl:pt-6" v-show="is_academi_order == true && !isOnlyPickup">
                                        <div class="font-bold py-3">
                                            Academy for Performance and shipping
                                        </div>
                                        <p class="mb-2">Delivery to GCOOPER Academy hanya dapat dilakukan ketika Anda telah memilih GCOOPER Academy. Jika Anda tidak memilih GCOOPER Academy, maka Anda tidak dapat menggunakan Delivery to GCOOPER Academy.</p>
                                        <span>
                                            - Pesanan yang telah memilih "Delivery to GCOOPER Academy" sebagai tujuan pengiriman akan dikirim setiap hari Senin & Jumat<br>
                                            - Bagi Anggota yang telah memilih GCOOPER Academy, dapat memilih "Delivery to Academy" dan menerima pesanan ke akademi yang telah didaftarkan<br>
                                            - Pesanan yang dikirim ke GCOOPER Academy adalah pengiriman gratis
                                        </span>
                                        <vSelect name="state" id="center" ref="center" :options="shipping_center_list" placeholder="Select"
                                               label="cnt_name" :reduce="value => value.cnt_cd" v-model='academi_cnt_cd'
                                               :clearable="false"
                                               class="block w-full text-gray-600 text-sm pointer-events-none bg-zinc-300"></vSelect>

                                    </div>
                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="w-full lg:w-1/2">
                                            <label for="firstName" class="block mb-3 text-sm font-semibold text-gray-500">Nama</label>
                                            <input name="name" id="firstName" ref="d_name" type="text" placeholder="First Name" maxlength="45"
                                                   v-model='this.shipping_info["d_name"]'
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                        </div>
                                        <div class="w-full lg:w-1/2 mt-2 lg:mt-0 ">
                                            <label for="mobile" class="block mb-3 text-sm font-semibold text-gray-500">Ponsel</label>
                                            <input name="mobile" id="mobile" ref="d_mobile" type="text" @keyup="regNumber('d_mobile')"
                                                   placeholder="Mobile" v-model='this.shipping_info["d_mobile"]' maxlength="13"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <!--                      <label for="lastName" class="block mb-3 text-sm font-semibold text-gray-500">Last Name</label>-->
                                            <!--                      <input name="lastName" id="lastName" ref="d_name2" type="text" placeholder="Last Name" v-model='this.shipping_info["d_name2"]'-->
                                            <!--                             class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">-->
                                        </div>
                                    </div>
                                    <div class="mt-4" v-show="is_academi_order == false && isOnlyPickup === false">
                                        <input type="button" @click="search_address" value="search address"
                                               class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">

                                        <div class="text-red-500 font-semibold text-xs md:text-sm pt-4 hidden">※ For DELIVERY to Academy
                                            kindly click "Delivery to GCOOPER Academy" button. For delivery to preferred address (House,
                                            Office & etc) kindly enter the address below.
                                        </div>
                                    </div>
                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="relative z-[11] lg:mt-0 mt-2 w-full bg-white  ">
                                            <label for="postcode" class="block mb-3 text-sm font-semibold text-gray-500">Postcode</label>
                                            <input name="postcode" id="postcode" ref="postcode" type="text" placeholder=""
                                                   v-model='this.shipping_info["d_post"]' readonly @click="search_address"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer"
                                                   maxlength="40">
                                        </div>
                                    </div>
                                    <div class="relative pt-3 xl:pt-6" v-if="msg_post !== ''">
                                        <div class="text-red-500">{{ this.msg_post }}</div>
                                    </div>

                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="relative z-[14] w-full bg-white ">
                                            <label for="state" class="block mb-3 text-sm font-semibold text-gray-500">Address1</label>
                                            <input name="address" id="state" ref="d_state" type="text" placeholder=""
                                                   v-model='this.shipping_info["d_address1_str"]' readonly @click="search_address"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                   maxlength="40">
                                        </div>
                                    </div>
                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="w-full bg-white ">
                                            <label for="address" class="block mb-3 text-sm font-semibold text-gray-500">Address2</label>
                                            <input name="address" id="address" ref="d_address" type="text"
                                                   placeholder="Please enter up to 200 characters."
                                                   :readonly="is_academi_order == true || isOnlyPickup"
                                                   v-model='this.shipping_info["d_address2"]'
                                                   @keyup="regText('d_address')"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                                   maxlength="200">
                                        </div>
                                    </div>
                                    <div class="relative pt-3 xl:pt-6" style="display: none;">
                                        <label for="note" class="block mb-3 text-sm font-semibold text-gray-500"> Notes (Optional)</label>
                                        <textarea name="note" id="note" rows="4" placeholder=""
                                                  class="flex items-center w-full px-4 py-3 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-600"></textarea>
                                    </div>
                                    <div v-if="isOnlyPickup===false" class="text-red-500 font-semibold text-xs md:text-base pt-4">
                                        ※ Please do not enter duplicate
                                        address information in the two fields below(Ex. State, City, Street, Post code). Only detailed
                                        information should be entered in the two fields below(Building, House etc).
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="summary" class="w-full sm:w-1/4 px-8">
                        <h1 class="font-semibold text-2xl border-b pb-8">Total Pembayaran</h1>
                        <div class="flex justify-between mt-10 mb-5">
                            <label class="font-semibold uppercase">Items {{ products.length }}</label>
                            <span class="font-semibold">
                            <p class="hidden text-lg">Rp {{ formatPrice(sum_item.price, 0) }}</p>
                            <p class="hidden text-orange-700 text-sm">Rp{{ formatPrice(sum_item.vat, 0) }}</p>
                            <p class="text-lg">Rp {{ formatPrice(sum_item.amt, 0) }}</p>
                            <p class="text-orange-700 text-sm">{{ formatPrice(sum_item.bv, 1) }} BV</p>
                            <p class="text-gray-700 text-sm">{{ formatPrice(total_weight, 2) }} KG</p>
                          </span>
                        </div>

                        <!--
                            ord_sht 생성단에서 shipping 을 계산한다.
                        -->
                        <div class="flex justify-between mt-10 mb-5" v-if="false">
                            <label class="font-semibold uppercase">Shipping</label>
                            <span class="font-semibold">
                            <p class="text-lg">Rp {{ formatPrice(fee_shipping[0].value, 0) }}</p>
                            <p class="text-gray-700 text-sm">{{ fee_shipping[0].text }}</p>
                          </span>
                        </div>

                        <!--            <div v-if="fee_shipping.length">
                                      <label class="font-medium inline-block my-3 text-sm uppercase">Shipping</label>
                                      <select name="select_shipping_fee" v-model="shipping_selected" @change="change_shipping_fee"
                                              class="form-select appearance-none block p-2 text-gray-600 w-full text-sm rounded">
                                        <option v-for="option in fee_shipping" :value="option.value">{{ option.text }} - RM {{ option.value }}</option>
                                      </select>
                                    </div>-->

                        <div v-if="fee_tax.length">
                            <label class="font-medium inline-block my-3 text-sm uppercase">TAX</label>
                            <select name="select_tax_fee" v-model="tax_selected" @change="change_tax_fee"
                                    class="form-select appearance-none block p-2 text-gray-600 w-full text-sm rounded">
                                <option v-for="option in fee_tax" :value="option.value">{{ option.text }} - {{ option.value }}</option>
                            </select>
                        </div>
                        <div class="border-t mt-8">
                            <div class="flex justify-between py-6">
                                <label class="font-semibold uppercase">Total Pembayaran</label>
                                <span class="font-semibold">
                                <p class="text-lg">Rp {{ formatPrice(total_cost, 0) }}</p>
                                <p class="text-orange-700 text-sm">{{ formatPrice(sum_item.bv, 1) }} BV</p>
                                </span>
                            </div>
                            <button v-if="!!isActivePurchase" @click="order"
                                    class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
                                Beli
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "../modalView";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Swal from 'sweetalert2'
import Pickup from '../../assets/module/pickup'


export default {
    name: "Cart",
    components: {
        // address_book,
        vSelect,
        alert,
        modalView,
        Loading,
        Swal
    },
    props: ['orderer', 'fail_message', 'mem_center', 'last_order_ceter', 'ord_cnt'],
    setup() {
        return {
            // products,
        }
    },
    watch: {
        is_academi_order: function () {
          if(this.is_academi_order){
            if(this.cnt_cd == "0500"){
              this.is_academi_order = false;
              this.change_shipping('Enroll');
              document.getElementById('order-info2').click();
              alert.confirm("Silahkan memilih GCOOPER Academy untuk menggunakan menu [Delivery to Academy].Pindah ke halaman untuk memilih GCOOPER Academy", "", "", {allowOutsideClick: false}).then((result) => {
                if(result){
                  location.href="/MyPersonInfo";
                }
              });
            }else{
              this.change_shipping('Academi');
              this.center_sel();
            }
          }
        }
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        max_qty: 99,
        sum_item: [],
        total_cost: 0,
        total_weight: 0,
        fee_shipping: [],
        shipping_selected: 0,
        fee_tax: [],
        tax_selected: 0,
        isEnterShipping: false,
        isEnterProduct: true,
        shipping_info: [],
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        selectedItems: [],
        state_list: [],
        city_list: [],
        street_list: [],
        post_list: [],
        center_list : [],
        shipping_center_list: [],
        academi_cnt_cd: "Select Academy",
        msg_post: '',
        is_academi_order: '',
        address_book_open: Boolean,  // 주소록 레이어 오픈
        loading: false,
        address_search_open: false,
        isMatchStateCity: false,
        is_order_possible: window.Laravel.is_order_possible,
        other_products: [],
        commition_cnt_cd: "Select Academy",
        type: '',
        isActivePurchase: window.isActivePurchase,
        isOnlyPickup: false,
        city_code : "",
        free_delivery_text : false,
        cnt_cd : "0500",
        rank_cd : "",
        result_limit_chk : false,
    }),
    created() {
        this.academi_cnt_cd = this.mem_center != "0500" ? this.mem_center : "Select Academy";
        if (this.last_order_ceter != "0000" && this.last_order_ceter != "0500" && this.last_order_ceter != null) {
            this.commition_cnt_cd = this.last_order_ceter;
        } else {
            this.commition_cnt_cd = this.academi_cnt_cd;
        }

        this.address_book_open = false;
        this.change_shipping('Enroll');
        this.memberInfo();
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        // 장바구니 가져오기
        this.getCart();
        this.getShippingCenterList();
        if (this.fail_message != "") {
            alert.alert(this.fail_message, "", "", {
                returnFocus: false, didClose: () => {
                    this.$refs.d_name.focus();
                }, didDestroy: () => {
                    this.$refs.d_name.focus();
                }
            }).then((result) => {
                if (result) {
                    this.$refs.d_name.focus();
                }
            });
        }

    },
    methods: {
        regText(colum) {
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace(reg, "");
            this.shipping_info[colum] = text;
        },
        regNumber(colum) {
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace(/[^0-9]/g, '');
            this.shipping_info[colum] = text;
        },
        // 장바구니 가져오기
        getCart() {
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/cart', {
                bz_cd: window.Laravel.bz_cd,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    // this.title = response.data[0].cate_name;
                    this.products = response.data;
                    // alert.alert("이미 신청한 기록이 있습니다.","","",{allowOutsideClick : false}).then(r => {
                    //   this.$emit('close-modal')
                    // });


                    if (this.products != []) { // 상품이 있으면 배송비, 택스 처리

                        this.isOnlyPickup = Pickup.isOnlyPickup(this.products);
                        this.totalCost(this.products);  //  결제상품 총액 / 배송비 포함 실결제액
                        this.isEnterShipping = true;

                        if (this.isOnlyPickup === true) {
                            document.getElementById('order-info5').click();
                        } else {
                            this.change_shipping('Enroll');
                        }

                    }
                    this.loading = false;   // this.getCartList(response.data);
                })
                .catch(e => {
                });
            axios.post(process.env.MIX_BACK_URL + '/api/getAsCartList', {
                bz_cd: window.Laravel.bz_cd,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.other_products = response.data;
                    this.loading = false;   // this.getCartList(response.data);
                })
                .catch(e => {
                });

        },
        get_info_address_book(id) {
            axios.post(process.env.MIX_BACK_URL + '/api/getAddressBook_item', {
                seq: id
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.shipping_info["d_name"]         = response.data.r_name;
                    this.shipping_info["d_name1"]        = '';
                    this.shipping_info["d_name2"]        = '';
                    this.shipping_info["d_address1"]     = response.data.r_addr1.replace(/\,/g, "|@|");
                    this.shipping_info["d_address1_str"] = response.data.r_addr1 ? (response.data.r_addr1).replace(/\|@\|/g, ", ") : '';
                    this.shipping_info["d_address2"]     = response.data.r_addr2 ? (response.data.r_addr2).replace(/\|@\|/g, " ") : "";
                    this.shipping_info["d_post"]         = response.data.r_post ?? '-';
                    this.shipping_info["d_telephone"]    = response.data.r_tel;
                    this.shipping_info["d_mobile"]       = response.data.r_mobile.replace(/\-/g, "");;
                    this.city_code                       = response.data.r_county;
                    this.address_book_open = false;
                    this.totalCost(this.products);
                })
        },
        // 수량 변경
        decrement(e, i) {
            if (this.products[i].qty > 1) {
                this.products[i].qty--;
                this.setChangeQty(this.products[i].seq_pd, this.products[i].qty);
                this.totalCost(this.products);
            }
        },
      async increment(e, i) {
          if (this.products[i].qty < this.max_qty) {
            let before_qty = this.products[i].qty
            await this.chkDayOrdLimit(this.products[i].seq_pd,1, before_qty).then(() => {
              if(this.result_limit_chk){
                this.products[i].qty++;
                this.setChangeQty(this.products[i].seq_pd, this.products[i].qty);
                this.totalCost(this.products);
              }
            });
          }
        },
      async changeQty(e, i) {
            if (this.products[i].qty < 1) {
              this.products[i].qty = 1;
            } else if (this.products[i].qty > this.max_qty) {
              this.products[i].qty = this.max_qty;
            }

            await this.chkDayOrdLimit(this.products[i].seq_pd,this.products[i].qty, 0).then(() => {
              if(this.result_limit_chk){
                this.setChangeQty(this.products[i].seq_pd, this.products[i].qty);
                this.totalCost(this.products);
              }else{
                this.products[i].qty = 1;
              }
            });
        },
        async chkDayOrdLimit(item, qty, before_qty = 0) {
          this.loading = true;
          //일일,주문건당 구매수량제한 먼저 검사
          await axios.post(process.env.MIX_BACK_URL + '/api/chk_day_ord_qty_limit', {
            item: item,
            add_qty: qty,
            cart_qty: before_qty,
          }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
            .then(response => {
              this.loading = false;
              if (response.data != "") {
                alert.alert(response.data);
                this.result_limit_chk = false;
              }else{
                this.result_limit_chk = true;
              }
            });
        },
        setChangeQty(item, qty) {

            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/change_qty', {
                item: item,
                qty: qty,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.loading = false;
                    if (response.data.msg) {
                        alert.alert(response.data.msg);
                    }

                });
        },
        // 장바구니 아이템 삭제
        removeItem(e, i) {

            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/remove_cart', {
                item: this.products[i].seq_pd,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.loading = false;
                    if (response.data.msg) {
                        alert.alert(response.data.msg);
                    }
                    this.getCart(window.Laravel.bz_cd);
                    this.getCountCartItems();
                });
        },

        getCountCartItems() {
            axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
                bz_cd: window.Laravel.bz_cd
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.$store.commit("setCartCount", response.data);
                });
        },
        // 선택 아이템 삭제
        removeSelect() {

            if (this.selectedItems.length === 0) {
                alert.alert("No selected items");
                return false;
            }
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/remove_cart', {
                item: this.selectedItems,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.loading = false;
                    // console.log(response.data);
                    if (response.data.msg) {
                        alert.alert(response.data.msg);
                    }
                    this.selectedItems = [];
                    this.getCart(window.Laravel.bz_cd);
                    this.getCountCartItems();
                });
        },
        // 쇼핑 계속하기
        prevUrl() {
            // console.log(window.history);
            window.history.go(-1);//
            // this.$router.go（-1）;
        },
        // 주문하기
        async order() {
            //사전 오픈 50BV 제한
            /*if(this.sum_item.bv < 50){
              alert.alert('Please order over 50BV.');
              return false;
            }*/
            if (!this.isEnterShipping) {
                this.isEnterShipping = true;
                this.isEnterProduct = false;

                alert.alert('Please enter your shipping information', "", "", {
                    returnFocus: false, focusConfirm: false,
                    didOpen: () => {
                    },
                    didDestroy: (toast) => {
                        this.$refs.d_name.focus();
                    }
                }).then((result) => {
                    if (result) {
                        //this.$refs.d_name.focus();

                    }
                });
                return true;
            }

            if (this.sum_item.amt == 0) {
                alert.alert('The configuration for the shopping basket cannot be found.');
                return false;
            }

            if (!this.shipping_info["d_name"]) {
                alert.alert('Please enter reciever name');
                return false;
            }
            // if(!this.shipping_info["d_name2"]){
            //   alert.alert('Please enter last name');
            //   return false;
            // }
            if (!this.shipping_info["d_mobile"]) {
                alert.alert('Please enter mobile No.');
                return false;
            }
            if (!this.shipping_info["d_address1"]) {
                alert.alert('Please select address1');
                return false;
            }
            /*
            if(!this.shipping_info["d_address2"]){
              alert.alert('Please enter detail address2');
              $("#address").focus();
              return false;
            }
            */

            const academyFormdata = {
                street: this.shipping_info["d_street"],
                post: this.shipping_info["d_post"],
                city: this.shipping_info["d_city"],
                state: this.shipping_info["d_state"],
                address: this.shipping_info["d_address1"],
                address2: this.shipping_info["d_address2"],
            }


            this.loading = true;
            await this.sleep(5000);
            /* 아카데미 주소와 동일 여부 체크 */
            axios.post(process.env.MIX_BACK_URL + '/api/similar_text', academyFormdata,
                {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    let similarChk = response.data;


                    /*아카데미 배송지 선택후 결제시 주소여부 체크안함*/
                    if (this.is_academi_order == true) {
                        similarChk = true;
                    }

                    if (similarChk == false) {
                        alert.alert('Please choose the Delivery to GCOOPER Academy button');
                        this.loading = false;
                        return false;
                    } else {
                        if (this.isMatchStateCity) {
                            this.isMatchStateCity = false;
                            this.loading = false;
                            return false;
                        }

                        let formdata = new FormData();
                        formdata.append("_token", this.csrf);
                        formdata.append("bz_cd", window.Laravel.bz_cd);
                        formdata.append("client_ip", window.Laravel.clientIp);
                        formdata.append("orderHost", location.host);
                        formdata.append("orderNation", window.Laravel.bz_cd);
                        formdata.append("orderPath", '일반주문');
                        formdata.append("sumPrice", this.sum_item.price);
                        formdata.append("sumVat", this.sum_item.vat);
                        formdata.append("sumAmt", this.sum_item.amt);
                        formdata.append("sumBv", this.sum_item.bv);
                        formdata.append("shippingFee", this.shipping_selected);
                        formdata.append("taxFee", this.tax_selected);
                        formdata.append("payTotalAmt", this.total_cost);
                        formdata.append("name", this.shipping_info.d_name);
                        formdata.append("firstName", this.shipping_info.d_name1);
                        formdata.append("lastName", this.shipping_info.d_name2);
                        formdata.append("mobile", this.shipping_info.d_mobile);
                        formdata.append("post", this.shipping_info.d_post);
                        formdata.append("addr1", this.shipping_info.d_address1);
                        formdata.append("addr2", this.shipping_info.d_address2);
                        formdata.append("note", document.shipping_info.note.value);
                        var cnt_cd = "0500";
                        if (this.is_academi_order == true && this.academi_cnt_cd != "Select Academy") {
                            cnt_cd = this.academi_cnt_cd;
                        } else {
                            if (this.commition_cnt_cd != "Select Academy") {
                                cnt_cd = this.commition_cnt_cd;
                            }
                        }
                        formdata.append("cnt_cd", cnt_cd);
                        formdata.append("academi_order", this.is_academi_order == true ? "1" : "0");
                        formdata.append("academi_cnt_cd", this.is_academi_order == true ? this.academi_cnt_cd : "");
                        formdata.append("_method", "PUT");
                        formdata.append("bz_cd", "id");
                        formdata.append("city_code", this.city_code);

                        axios.post(process.env.MIX_BACK_URL + '/api/order', formdata,
                            {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                            .then(response => {
                                this.loading = false;

                                // console.log(response.data);
                                if (response.data.msg) {
                                    alert.alert(response.data.msg);
                                }
                                if (response.data.result == 'Success') {
                                    let form = document.shipping_info;
                                    form.action = '/order';
                                    form.order_seq.value = response.data.order_seq;
                                    form.shipping_fee.value = this.shipping_selected;
                                    form.tax_fee.value = this.tax_selected;
                                    form.pay_total_amt.value = this.total_cost;
                                    form.submit();
                                }
                                // this.getCart(window.Laravel.bz_cd);
                            });
                    }
                });


            // this.$router.go（-1）;
        },
        matchStateCity(item) {
            let state = item.state;
            let city = item.district;
            if ((this.shipping_info["d_address"].toUpperCase().match(state.toUpperCase())) && (this.shipping_info["d_address"].toUpperCase().match(city.toUpperCase()))) {
                alert.alert('Please enter State/City/Postcode only in Search Address.');
                this.isMatchStateCity = true;
                return false;
            }
        }, sleep(ms) {
            return new Promise((r) => setTimeout(r, ms));
        },
        getShippingFee() {
            /* 배송 기본료  체크 : 배송지별 무료배송 요건(d_free_bv) 달성 시 무료배송, 미달 시 지역별 배송료 부과(tariff) */
            let free_fee_term   = 130;
            let isExistTariff   = this.shipping_info['d_tariff'] !== undefined && this.shipping_info['d_tariff'] !== 0;

            if(!isExistTariff) { /** 지역별 배송비 찾기 */
            this.checkTariff(this.shipping_info["d_address1"], '');
            }
            free_fee_term = this.shipping_info['d_free_bv'];

            /* 무료 배송 조건 체크 */
            if (this.sum_item.bv >= free_fee_term) { // 무료배송 기준 이상인 경우
                /* 배송비 */
                this.fee_shipping = [{
                    text: 'FREE Shipping',
                    value: 0
                }];
                this.shipping_selected = 0; // 배송비

            } else if (this.isOnlyPickup === true) {

                this.fee_shipping = [{
                    text: 'FREE Shipping',
                    value: 0
                }];
                this.shipping_selected = 0; // 배송비

            } else {  // 무료배송 기준 이하인 경우
                let value = this.shipping_info['d_tariff'];  // 지역 별 기본 배송비
                /* 배송비 */
                this.fee_shipping = [{
                    text: parseFloat(this.total_weight).toFixed(2) + 'KG',
                    value: value
                }];
                this.shipping_selected = value; // 배송비
            }

            /* 아카데미 주문일 경우 무료 배송 */
            if (this.is_academi_order == true) {
                /* 배송비 */
                this.fee_shipping = [{
                    text: 'FREE Shipping',
                    value: 0
                }];
                this.shipping_selected = 0; // 배송비
                this.total_cost = this.sum_item.amt + parseFloat(this.shipping_selected);
            }

            if (this.ord_cnt == 0 && this.sum_item.bv > 20) {
                /* 배송비 */
                this.fee_shipping = [{
                    text: 'FREE Shipping',
                    value: 0
                }];
                this.shipping_selected = 0; // 배송비
                this.total_cost = this.sum_item.amt + parseFloat(this.shipping_selected);
            }

            if (this.shipping_selected) this.total_cost = this.sum_item.amt + parseFloat(this.shipping_selected);  // 합산금액 변경

        },
        checkTariff(address, seq_oab) { /** 지역별 배송비 조회 */
        axios.post(process.env.MIX_BACK_URL+'/api/getIdTariff',{
            address : address,
            seq_oab : seq_oab,
            bz_cd   : "id"
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
                this.shipping_info.d_tariff  = 0;
                this.shipping_info.d_free_bv = 0;
                if(response.data.ret == '1') {
                    this.shipping_info.d_tariff    = response.data.tariff;
                    this.shipping_info.d_free_bv   = response.data.free_bv;
                }
            })
            .catch(e => {
            });
        },
        // 장바구니 금액 합산
        totalCost(data) {  // 토탈 금액 합산 기능

            this.sum_item.price = this.sumPrice(data);
            this.sum_item.vat = this.sumVat(data);
            this.sum_item.amt = this.sumAmt(data);
            this.sum_item.bv = this.sumBV(data);
            this.total_cost = this.sum_item.amt;
            if (this.tax_selected) this.total_cost += parseFloat(this.tax_selected);

        },
        sumPrice(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.price * el.qty), 0);
        },
        sumVat(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.vat * el.qty), 0);
        },
        sumAmt(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.amt * el.qty), 0);
        },
        sumBV(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.pv * el.qty), 0);
        },
        change_shipping_fee() {  // 배송비 합산
            this.totalCost(this.products);
            // this.total_cost = this.add_shipping_fee();
        },
        change_tax_fee() {
            this.totalCost(this.products);
        },
        change_shipping(data) {  // 배송지 변경
            this.loading = true;
            this.msg_post = "";
            // 픽업 주소 add by hskim :: 2023-07-21 오후 2:44
            if (data === 'Pickup') {

                const getAddress = Pickup.getAddress();

                this.shipping_info["d_name"]         = this.orderer["o_name"];
                this.shipping_info["d_mobile"]       = this.orderer["o_mobile"];

                this.shipping_info["d_address1"]     = getAddress['d_address1'];
                this.shipping_info["d_address1_str"] = (getAddress['d_address1']) ? getAddress['d_address1'].replace(/\|@\|/g, ", ") : ''; // 보여주기용
                this.shipping_info["d_address2"]     = getAddress['d_address2'];
                this.shipping_info["d_post"]         = getAddress['d_post'];

                document.getElementById('order-info1').disabled = true
                document.getElementById('order-info2').disabled = true
                document.getElementById('order-info3').disabled = true
                document.getElementById('order-info4').disabled = true
                document.getElementById('order-info5').checked = true;
                this.loading = false;
                return;

            } else if (data === 'Same') {

                this.shipping_info["d_name"] = this.orderer["o_name"];
                this.shipping_info["d_name1"] = this.orderer["o_name1"];
                this.shipping_info["d_name2"] = this.orderer["o_name2"];
                this.shipping_info["d_address1"] = this.orderer["o_addr1"];
                this.shipping_info["d_address1_str"] = (this.orderer["o_addr1"]) ? this.orderer["o_addr1"].replace(/\|@\|/g, ", ") : ''; // 보여주기용
                this.shipping_info["d_address2"] = this.orderer["o_addr2"];
                this.shipping_info["d_post"] = this.orderer["o_post"];
                this.shipping_info["d_telephone"] = this.orderer["o_telephone"];
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];
            } else if (data == 'Academi') {
                this.shipping_info["d_name"] = this.orderer["o_name"];
                this.shipping_info["d_name1"] = this.orderer["o_name1"];
                this.shipping_info["d_name2"] = this.orderer["o_name2"];
                this.shipping_info["d_address1"] = '';
                this.shipping_info["d_address1_str"] = '';
                this.shipping_info["d_address2"] = '';
                this.shipping_info["d_post"] = '';

                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];

            } else {
                this.shipping_info["d_name"] = '';
                this.shipping_info["d_name1"] = '';
                this.shipping_info["d_name2"] = '';
                this.shipping_info["d_address1"] = '';
                this.shipping_info["d_address1_str"] = '';
                this.shipping_info["d_address2"] = '';
                this.shipping_info["d_post"] = '';
                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = '';
            }
            if (data == 'Manage') {
                this.address_book_open = true;
            } else {
                // this.address_book_open = false;
            }
            if (data == 'Academi') {
                this.is_academi_order = true;
                this.getShippingFee();
                this.center_sel();
            } else {
                this.is_academi_order = false;
                this.getShippingFee();
            }
            this.loading = false;
        },
        // 주소록 레이어 오픈
        close_address_book() {
            this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value, digit) {
            // 코드 개선 add by hskim :: 2023-07-21 오후 2:56
            value = isNaN(value) ? 0 : value;
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        search_address() {

            if (this.isOnlyPickup === true) {

                Swal.fire({
                    title: i18n.shop.pickup.pickupOnlyTitle,
                    icon: 'warning',
                    html:
                        'You can\'t change the address of the pickup product.',
                    showCloseButton: true,
                    confirmButtonText:
                        'OK',
                    confirmButtonAriaLabel: 'closed',
                })

            } else {
                if (this.is_academi_order == false) {
                    this.address_search_open = true;
                }
            }
        },
        set_address(value) {
            this.address_search_open             = false;
            this.shipping_info["d_address1"]     = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join('|@|');
            this.shipping_info["d_address1_str"] = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join(', ');
            this.shipping_info["d_post"]         = value.postcode;
            this.shipping_info["d_tariff"]       = value.tariff;  // 배송료
            this.shipping_info["d_free_bv"]      = value.free_bv; // 무료배송 기준 (미달시 배송료 부과(tariff))
            this.city_code                       = value.city_code;
            this.totalCost(this.products);

        },
        close_address_search(){
            this.address_search_open = false;
        },
        center_sel(value){
            this.loading = true;
            this.shipping_center_list.forEach((value, index) => {
                if(this.is_academi_order == true && value.cnt_cd == this.academi_cnt_cd){
                    this.shipping_info["d_post"] = value.post;
                    this.shipping_info["d_address1"] = value.addr1;
                    this.shipping_info["d_address1_str"] = (value.addr1) ? value.addr1.replace(/\|@\|/g, ", ") : '';
                    this.shipping_info["d_address2"] = value.addr2;
                    this.city_code = value.county;

                }
            });
            this.loading = false;
        },
        getShippingCenterList(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCenter',{
                bz_cd: 'id',
                type: 'shipping',
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.shipping_center_list = response.data;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });

        },
        link(value){
            location.href = value;
        },
        onCancel() {
        },
        memberInfo(){
          axios.post(window.Laravel.back_url+'/api/memberInfo',{
          }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.cnt_cd = response.data.cnt_cd;
            this.rank_cd = response.data.rank_cd;
          });
        }
    }
}
</script>
<style>
.vs__search {
    font-size: 1.5em;
    font-weight: 500;
    padding: 0.05rem;
    border: none;
    /* margin-top: 35px; */
}

.badge_box {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.badge_box > img {
    width: 25% !important;
    top: 8px;
    right: 8px;
    position: absolute;
    z-index: 1;
    background: transparent !important;
}

.coming_box {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.coming_box > img {
    width: 25% !important;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
    background: transparent !important;
}

.sold-out-box2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.sold-out-box2 > img {
    width: 100% !important;
    top: 0px;
    right: 0px;
    position: absolute;
    z-index: 1;
    background: transparent !important;
}
/*장바구니 노티스*/
.cart-notice {
    width: 80%;
    max-width: 1600px;
    margin: 0 auto;
    border: 1px solid #eee;
    background: #eee;
    padding: 15px 20px 15px 30px;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.cart-notice span{font-weight:bold; color:red;}

/*장바구니 배송비 정책 노티스*/
.deli-condition {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    display: inline-block;
    margin-top: 5px;
}

.deli-condition .delivery {
    display: inline-block;
    color: #ff5959;
    width:calc(100% - 20px);
    background: #eee;
    padding: 10px 10px;
}
</style>
