<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>

    <section class="container mx-auto lg:py-6 md:p-0">
        <div class="bg-white">
            <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
                <MypageTop :laravel="laravel"></MypageTop>
                <div class="w-full lg:flex lg:justify-start">
                    <div class="hidden lg:block xl:block">
                        <SideMenu></SideMenu>
                    </div>
                    <div class="w-full">
                        <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
                            <div class="w-full pb-10">
                                <div class="w-full item-start space-y-2">
                                    <!--     화면 타이틀    -->
                                    <div class="flex justify-between">
                                        <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">GC HEART</h1>
                                    </div>
                                    <!--     하트 대시보드    -->
                                    <section class="my-2 flex border-2 border-gray-500 rounded-xl">
                                        <div class="relative block min-w-[300px] px-10 my-5">
                                            <div class="relative w-full mx-auto text-center content-center">
                                                <div class="w-24 mx-auto"><img src="/images/heart/gc_heart_icon_100x100.png?ver=1.0" alt="하트 아이콘" /></div>
                                                <div class="w-36 mx-auto">My GCOOP Hearts</div>
                                            </div>
                                        </div>
                                        <div class="w-full my-10 px-5 border-l border-dashed border-gray-500">
                                            <dl class="w-full flex justify-between py-3">
                                                <dt>{{ i18n.shop.mypage.availableHeart }}</dt>
                                                <dd><span class="signiture h_count">{{ formatPrice(h_stamp.pv_now ?? 0,2) }}</span>&nbsp;Hearts</dd>
                                            </dl>
                                            <dl class="w-full flex justify-between py-3 ">
                                                <dt>{{ i18n.shop.mypage.heartstobeEarned }}</dt>
                                                <dd><span class="h_count">{{ formatPrice(h_stamp.pv_ready ?? 0,2) }}</span>&nbsp;Hearts</dd>
                                            </dl>
                                            <dl class=" flex " style="display: none;">
                                                <dt>30일 이내 소멸예정 하트</dt>
                                                <dd><span class="h_count">{{ formatPrice(h_stamp.pv_use_ord ?? 0) }}</span>&nbsp;하트</dd>
                                            </dl>
                                        </div>
                                    </section>
                                    <!--     하트 배너    -->
                                    <div class="py-2">
<!--                                        <a href="/AsCart"><img src="/images/heart/gc_heart_banner_us_1280x480.jpg?ver=1.0" class="rounded-xl" alt="하트 배너" /></a>-->
                                      <img src="/images/heart/gc_heart_banner_us_1280x480.jpg?ver=1.0" class="rounded-xl" alt="하트 배너" />
                                    </div>
                                    <!--     기간 조회    -->
                                    <div class="w-full py-2 px-3 flex justify-between bg-slate-50 hover:bg-white cursor-pointer border rounded-md searchForm" v-on:click="showMessage = !showMessage">
                                        <div class="basis-1/2">Date Designation</div>
                                        <div class="basis-1/2 flex justify-end" @click="showHideContents($event)">
                                            <MinusSmIcon class="h-6 w-6 minus-contents-body contents-active-none" aria-hidden="true" />
                                            <PlusSmIcon class="h-6 w-6 plus-contents-body" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div class="w-full p-2 rounded bg-white lg:bg-slate-50 contents-body contents-active" v-if="showMessage">
                                        <div>
                                            <p class="p-0 text-sm font-semibold lg:p-2">
                                                <span class="text-md">{{ i18n.myoffice.myoSearchForm.selectDate }}</span>
                                                <span class="text-blue-600 text-md font-medium block border border-slate-300 bg-white p-2 text-center lg:border-0 lg:inline-block lg:px-2 lg:bg-transparent lg:p-0 mt-2 lg:mt-0 xl:mt-0">
                                                    {{ this.s_date }} ~ {{ this.e_date }}
                                                </span>
                                            </p>
                                            <div class="w-full py-2 grid grid-cols-2 lg:grid-cols-5 gap-2 lg:p-2.5">
                                                 <button
                                                    @click="selectDate(dates)" class="border bg-white hover:bg-black hover:text-white rounded-md p-2 border-slate-300 text-sm lg:text-md text-black"
                                                    :class="{isSelected: dates.selected }"
                                                    v-for="dates in dateSelect" :key="dates.code">
                                                    {{ dates.label }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full mt-10">
                                        <!-- 하트 콘텐츠 탭 -->
                                        <ul class="flex border-b">
                                            <li class="-mb-px mr-1">
                                                <button class="bg-white inline-block py-3 px-6 text-[#444] hover:text-blue-800 font-semibold" :class="[{'on':tab == 1 },{'text-blue-500':tab == 1 }] " @click="tab=1">{{ i18n.shop.mypage.fullDetails }}</button>
                                            </li>
                                            <li class="mr-1">
                                                <button class="bg-white inline-block py-3 px-6 text-[#444] hover:text-blue-800 font-semibold" :class="[{'on':tab == 2 },{'text-blue-500':tab == 1 }] " @click="tab=2">{{ i18n.shop.mypage.earnings }}</button>
                                            </li>
                                            <li class="mr-1">
                                                <button class="bg-white inline-block py-3 px-6 text-[#444] hover:text-blue-800 font-semibold" :class="[{'on':tab == 3 },{'text-blue-500':tab == 1 }] " @click="tab=3">{{ i18n.shop.mypage.usehistory }}</button>
                                            </li>
                                        </ul>
                                        <div class="heart_tab_box"><!-- 구 view-detail-box2 -->
                                            <!--탭 콘텐츠 -->
                                            <div class="heart_tab_cont "><!-- 구 tab-detail-box2 -->
                                                <!-- 전체내역 -->
                                                <div class="heart_cont_box mgt_20" v-show="tab == 1"> <!-- 구 tab-view-box2 -->
                                                    <div class="relative overflow-x-auto  sm:rounded-lg mt-4">
                                                        <span>There are <span class="text-blue-400 font-semibold">{{ this.total_count }}</span> orders in total.</span>
                                                        <!-- 모바일 사이즈 -->
                                                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full shadow-md mt-1.5 lg:border lg:border-slate-300">
                                                            <ul class="text-sm text-gray-700 uppercase bg-white dark:bg-gray-700 dark:text-gray-400 border-b font-semibold bg-slate-50 hidden lx:flex lg:flex items-center">
                                                                <li class="px-6 py-3 basis-1/7 text-center">No</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.Date }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.myoffice.Commission.type }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.heart }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.remark }}</li>
                                                            </ul>
                                                            <ul v-for="(row,key) in h_stamp_list" role="row" class=" rounded-md lg:rounded-none xl:rounded-none border dark:bg-gray-200 dark:border-gray-100  block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent">
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="basis-1/7 dark:text-white lg:text-center xl:text-center lg:border-0 justify-between items-center px-2 py-3 border-black border-b hidden lg:block mb-2">
                                                                    {{ h_stamp_list_total - (( h_stamp_list_page-1)*20 + key)  }}
                                                                </li>
                                                                <li v-show="row.kind == 2" class="block  lg:hidden ">
                                                                    <MinusCircleIcon class="w-10" />
                                                                </li>
                                                                <li v-show="row.kind == 1" class="block  lg:hidden ">
                                                                    <PlusCircleIcon class="w-10" />
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.reg_date }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.kinds }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    <span class="h_count">{{ formatPrice(row.pv,2) }}</span>{{ i18n.shop.mypage.heart }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.remark }}
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div><!-- // heart_table1 -->
                                                    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
                                                        <v-pagination
                                                            v-model="h_stamp_list_total"
                                                            :pages=h_stamp_list_page
                                                            :range-size="1"
                                                            active-color="#DCEDFF">
                                                        </v-pagination>
                                                    </div>
                                                </div><!-- // heart_cont_box -->

                                                <div class="heart_cont_box mgt_20" v-show="tab == 2"> <!-- 구 tab-view-box2 -->
                                                    <div class="relative overflow-x-auto  sm:rounded-lg mt-4">
                                                        <span>There are <span class="text-blue-400 font-semibold">{{ this.total_count }}</span> orders in total.</span>
                                                        <!-- 모바일 사이즈 -->
                                                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full shadow-md mt-1.5 lg:border lg:border-slate-300">
                                                            <ul class="text-sm text-gray-700 uppercase bg-white dark:bg-gray-700 dark:text-gray-400 border-b font-semibold bg-slate-50 hidden lx:flex lg:flex items-center">
                                                                <li class="px-6 py-3 basis-1/7 text-center">No</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.Date }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.myoffice.Commission.type }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.heart }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.remark }}</li>
                                                            </ul>
                                                            <ul v-for="(row,key) in h_stamp_list_reg" role="row" class="rounded-md lg:rounded-none xl:rounded-none border dark:bg-gray-200 dark:border-gray-100  block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent">
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="basis-1/7 dark:text-white lg:text-center xl:text-center lg:border-0 justify-between items-center px-2 py-3 border-black border-b hidden md:block mb-2">
                                                                    {{ h_stamp_list_reg_total - (( h_stamp_list_reg_page-1)*20 + key)  }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.reg_date }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.kinds }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    <span class="h_count">{{ formatPrice(row.pv,2) }}</span>{{ i18n.shop.mypage.heart }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.remark }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
                                                            <v-pagination
                                                                v-model="h_stamp_list_reg_total"
                                                                :pages=h_stamp_list_reg_page
                                                                :range-size="1"
                                                                active-color="#DCEDFF">
                                                            </v-pagination>
                                                        </div>
                                                    </div><!-- // heart_table1 -->
                                                </div><!-- // heart_cont_box -->


                                                <div class="heart_cont_box mgt_20" v-show="tab == 3"> <!-- 구 tab-view-box2 -->
                                                    <div class="relative overflow-x-auto  sm:rounded-lg mt-4">
                                                        <span>There are <span class="text-blue-400 font-semibold">{{ this.total_count }}</span> orders in total.</span>
                                                        <!-- 모바일 사이즈 -->
                                                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full shadow-md mt-1.5 lg:border lg:border-slate-300">
                                                            <ul class="text-sm text-gray-700 uppercase bg-white dark:bg-gray-700 dark:text-gray-400 border-b font-semibold bg-slate-50 hidden lx:flex lg:flex items-center">
                                                                <li class="px-6 py-3 basis-1/7 text-center">No</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.Date }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.myoffice.Commission.type }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.heart }}</li>
                                                                <li class="px-6 py-3 basis-1/5 text-center">{{ i18n.shop.mypage.remark }}</li>
                                                            </ul>
                                                            <ul v-for="(row,key) in h_stamp_list_use" role="row" class="rounded-md lg:rounded-none xl:rounded-none border dark:bg-gray-200 dark:border-gray-100  block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent">
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="basis-1/7 dark:text-white lg:text-center xl:text-center lg:border-0 justify-between items-center px-2 py-3 border-black border-b hidden md:block mb-2">
                                                                    {{ h_stamp_list_use_total - (( h_stamp_list_use_page-1)*20 + key)  }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.reg_date }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.kinds }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    <span class="h_count">{{ formatPrice(row.pv,2) }}</span>{{ i18n.shop.mypage.heart }}
                                                                </li>
                                                                <li :class="[{'text-gray-400':row.status == 4},'text-gray-900' ]" class="w-full dark:text-white lg:basis-1/5 text-md lg:text-md xl:text-md">
                                                                    {{ row.remark }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
                                                            <v-pagination
                                                                v-model="h_stamp_list_use_total"
                                                                :pages=h_stamp_list_use_page
                                                                :range-size="1"
                                                                active-color="#DCEDFF">
                                                            </v-pagination>
                                                        </div>
                                                    </div><!-- // heart_table1 -->
                                                </div><!-- // heart_cont_box -->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <!-- 하트 노티스 -->
                                <div class="w-full py-2 flex" v-show="false">
                                    <h2>Heart Point Guide</h2>
                                    <button class="border bg-white hover:bg-black hover:text-white rounded-md px-2 ml-2 border-slate-300 text-sm lg:text-md text-black"
                                        @click="showGuide = !showGuide">
                                        More
                                    </button>
                                </div><!--//heart_acco_tit-->
                                <div class="block p-4 border rounded-xl" v-if="showGuide">
                                    <div class="acco_cont_box">
                                        [Heart definition]<br>
                                        - This is the name of the point paid when purchasing in units of 50BV for auto-ship purchase.<br>
                                        - When you purchase a product through auto-ship purchase, hearts will be accumulated for each 50BV section.<br>
                                        <br>
                                        [Hearts]<br>
                                        - Earn 10% hearts of purchase amount. <br>
                                        - You cannot purchase more than 499BV for one time purchase.<br>
                                        <br>
                                        [Heart accumulated]<br>
                                        - Hearts will be accumulated upon completion of the Autoship purchase payment.<br>
                                        <br>
                                        [Using a heart]<br>
                                        - You can use it when you earn more than 20 hearts.<br>
                                        - If the remaining hearts are less than 20 after using the heart, it cannot be used.<br>
                                        - Heart can only be used for regular purchase (Autoship purchase is not available)<br>
                                        - You can pay combine with cards and hearts.<br>
                                        - When using the heart, the BV of the purchased product is not recognized as a performance.<br>
                                        - You cannot transfer hearts to other members.<br>
                                        <br>
                                        [Hearts expire]<br>
                                        - The accumulated hearts will be initialized if continuous payments for Autoship purchases are discontinued.<br>
                                        - The accumulated hearts will disappear if you withdraw from membership.<br>
                                        - The accumulated hearts will automatically expire 24 months after the date Hearts are issued.<br>
                                        <br>
                                        [Exchange/Return/Shipping fee]<br>
                                        - If you cancel or return the order of the product you purchased with Hearts, the Hearts will be restored. (The order with Hearts is only for regular purchase)<br>
                                        - Products purchased with accumulated hearts might also will be retrieved if you return Autoship purchase.<br>
                                        - Partial returns/exchanges of products purchased through hearts are not allowed, so please return all of them to us and purchase products again.<br>
                                        - Shipping policy is the same as current policy.<br>
                                    </div><!--//acco_cont_box-->
                                </div><!--// heart_acco_cont-->
                            </div>
                        </div><!-- // heart_tab_cont -->
                    </div><!-- // heart_tab_box -->

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import VueSimpleAlert from "vue3-simple-alert";
import {MinusSmIcon, PlusSmIcon, ShoppingCartIcon} from "@heroicons/vue/outline";
import modalView from "../../modalView";
import vSelect from "vue-select";
import moment from "moment/moment";
import { MinusCircleIcon,PlusCircleIcon } from '@heroicons/vue/outline'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "heartManager",
    components : {
        MinusSmIcon,
        PlusSmIcon,
        MypageTop,
        SideMenu,
        MinusCircleIcon,
        PlusCircleIcon,
        Loading,
        VPagination
    },
    data: () => ({
        showMessage: false,
        showGuide: false,

        i18n: window.i18n,
        laravel: window.Laravel,
        h_stamp : {
          "pv_now" : 0,
          "pv_ready" : 0,
          "pv_use_ord" : 0
        },
        tab : 1,
        h_stamp_list  : "",
        h_stamp_list_use : "",
        h_stamp_list_reg : "",
        dateSelect: [
            { code: "0", label: i18n.search.allday, selected: false},
            { code: "1", label: i18n.search.before1month, selected: false},
            { code: "3", label: i18n.search.before3month, selected: true},
            { code: "6", label: i18n.search.before6month, selected: false},
            { code: "12", label: i18n.search.before12month, selected: false},
        ],
        h_stamp_list_total : 0,
        h_stamp_list_reg_total : 0,
        h_stamp_list_use_total : 0,
        h_stamp_list_page : 1,
        h_stamp_list_reg_page : 1,
        h_stamp_list_use_page : 1,
        loading : false,
        fullPage : true,
    }),
    created() {
        this.s_date = moment().subtract(3,'months').format('DD-MM-YYYY');
        this.e_date = moment().format('DD-MM-YYYY');
        this.message_month = " for the last 3 months";
        this.submit();
    },
    methods: {
        submit(){
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/getHeartList', {
                s_date: this.s_date,
                e_date: this.e_date,
                page_size_list : this.h_stamp_list_page,
                page_size_reg : this.h_stamp_list_reg_page,
                page_size_use : this.h_stamp_list_use_page,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {

                    this.h_stamp = (response.data.h_stamp === null  ) ? this.h_stamp : response.data.h_stamp;
                    this.h_stamp_list_total = response.data.h_stamp_list_total;
                    this.h_stamp_list_reg_total = response.data.h_stamp_list_reg_total;
                    this.h_stamp_list_use_total = response.data.h_stamp_list_use_total;

                    this.h_stamp_list = response.data.h_stamp_list.data;
                    this.h_stamp_list_use = response.data.h_stamp_list_use.data;
                    this.h_stamp_list_reg = response.data.h_stamp_list_reg.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.loading = false;

                    // window.history.go(-1);
                });

        },
        formatPrice(value,digit) {
            // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
            value = isNaN(value) ? 0 : value;
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        selectDate(item) {
            if(item.code == "0"){
                this.s_date = "";
                this.message_month = "";
            }else{
                if(item.code == "1"){
                    this.message_month = " for the last one month";
                }else{
                    this.message_month = " for the last " + item.code + " months";
                }
                this.s_date = moment().subtract(item.code,'months').format('DD-MM-YYYY');
            }
            for(var i=0 ; i< this.dateSelect.length;i++){
                this.dateSelect[i].selected = false;
            }
            item.selected = !item.selected;
            this.submit();
        },
        showHideContents: function(e) {
            if($(".contents-body").hasClass("contents-active")){
                $(".contents-body").removeClass('contents-active');
                $(".contents-body").addClass('contents-active-none');
                $(".plus-contents-body").removeClass('contents-active-none');
                $(".minus-contents-body").addClass('contents-active-none');
            }else{
                $(".contents-body").removeClass('contents-active-none');
                $(".contents-body").addClass('contents-active');
                $(".minus-contents-body").removeClass('contents-active-none');
                $(".plus-contents-body").addClass('contents-active-none');
            }
        },
    }
}
</script>

<style scoped>
.isSelected {
    background: #000;
    color: #fff
}
.contents-active{
    display:block;
}
.contents-active-none{
    display:none;
}
.on {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity));
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
</style>
