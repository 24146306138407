/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//Vue = require('vue').default; // Use import Vue from 'vue' instead of window.Vue = require("vue");
import { createApp } from 'vue'
// import router  from './router'
import { i18nVue } from 'laravel-vue-i18n'
import alert from 'vue3-simple-alert'
import common from './assets/script.js'

// vuex 추가 반영 add by hskim :: 2023-08-04 오전 9:31
import store from './store/index'

// import components from  './components';
// import ExampleComponent from './components/ExampleComponent.vue';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import LayoutTop from './components/shop/LayoutTop';
import LayoutBottom from './components/shop/LayoutBottom';
import LayoutMobileFooterMenu from './components/shop/LayoutMobileFooterMenu';
import LayerQuick from './components/shop/LayerQuick';
// import MainPage from './components/MainPage'
import VisualBanners from './components/main/VisualBanners';
// import NewProducts from './components/main/NewProducts';
import ProductList from './components/shop/ProductList';
import ProductItem from './components/shop/ProductItem';
import ProductItemTest from './components/shop/ProductItemTest';

import Cart from './components/shop/Cart';
import Direct from './components/shop/Direct';
import Order from './components/shop/Order';
import OrderSummary from './components/shop/OrderSummary';
/*MYPAGE */
import Mypage from './components/mypage/Mypage';
import MypageOrderList from './components/mypage/Order/OrderList';
import MypageOrderView from './components/mypage/Order/OrderView';
import MypageNoticeList from './components/mypage/Board/NoticeList';
import MypageBoardView from './components/mypage/Board/BoardView';
import MypageFAQ from './components/mypage/Board/FAQ';
import MypageInquiryList from './components/mypage/Board/InquiryList';
import MypageInquiryModify from './components/mypage/Board/InquiryModify';
import MypageInquiryView from './components/mypage/Board/InquiryView';
import MypageAcademyPage from './components/mypage/OnlineAcademy/AcademyPage';
import MypageAcademyView from './components/mypage/OnlineAcademy/AcademyView';
import MemberInfo from './components/mypage/member/memberInfo';
import MemberFind from './components/mypage/member/memberFind';
import UserDel from './components/mypage/member/userDel';
import idpwchk from './components/mypage/member/idpwchk';
import AddressList from './components/mypage/Order/AddressList';
import AddressModify from './components/mypage/Order/AddressModify';

import AutoShipModify from './components/mypage/AutoShip/AutoShipModify';
import AutoShipWithdrawal from './components/mypage/AutoShip/AutoShipWithdrawal';
import HeartManager from './components/mypage/AutoShip/heartManager';


/*MYOFFICE */
import MyofficeMain from './components/myoffice/MyofficeMain';
import NotieTitle from './components/myoffice/NoticeTitle';
import MyofficeNav from './components/myoffice/MyofficeNav';
import MyofficeDashBoard from './components/myoffice/MyofficeDashBoard';
import MyRankPromotion from './components/myoffice/Promotion/MyRankPromotion';
import MyMember from './components/myoffice/Member/MyMember';
import MyMemberFavorites from './components/myoffice/Member/MyMemberFavorites';
import MyMemberLegBox from './components/myoffice/Member/MyMemberLegBox';
import MyMemberLegBoxCompress from './components/myoffice/Member/MyMemberLegBoxCompress';
import MyMemberLegLine from './components/myoffice/Member/MyMemberLegLine';
import MyMemberUpChange from './components/myoffice/Member/MyMemberUpChange';
import MyPersonInfo from './components/myoffice/Member/MyPersonInfo';
import MyPersonInfoModify from './components/myoffice/Member/MyPersonInfoModify';
import MyPersonInfoModifyCenter from './components/myoffice/Member/MyPersonInfoModifyCenter';
import MyBenefitSearch from './components/myoffice/Commission/MyBenefitSearch';
import Mycp58 from './components/myoffice/Commission/Mycp58';
import MyBenefitDetailHistory from './components/myoffice/Commission/MyBenefitDetailHistory';
import MyBenefitTotalSearch from './components/myoffice/Commission/MyBenefitTotalSearch';
import MyBenefitList from './components/myoffice/Commission/MyBenefitList';
import MyBenefitTranslHistory from './components/myoffice/Commission/MyBenefitTranslHistory';
import MyBenefitCalendar from './components/myoffice/Commission/MyBenefitCalendar';
import MyBenefitAnalysis from './components/myoffice/Commission/MyBenefitAnalysis';
import MyBenefitAllowanceReturn from './components/myoffice/Commission/MyBenefitAllowanceReturn';
import MyNotice from './components/myoffice/Board/MyNotice';
import MyData from './components/myoffice/Board/MyData';
import BoardView from './components/myoffice/Board/BoardView';
import MyInQuriy from './components/myoffice/Board/MyInQuriy';
import MyInQuriyModify from './components/myoffice/Board/MyInQuriyModify';
import MyInQuriyView from './components/myoffice/Board/MyInQuriyView';
import MyOrderList from "./components/myoffice/Order/MyOrderList";
import MyOrderView from "./components/myoffice/Order/MyOrderView";
import MyMemberRank from "./components/myoffice/Member/MyMemberRank";
import MyforinerUpload from "./components/myoffice/Member/MyforinerUpload";
import MyViewOrder from "./components/myoffice/Order/MyViewOrder";
import MyOrderDown from "./components/myoffice/Order/MyOrderDown";
import MyOrderDirDown from "./components/myoffice/Order/MyOrderDirDown";
import MyOrderLegs from "./components/myoffice/Order/MyOrderLegs";
import MyWeeklyPromotion from "./components/myoffice/MyWeeklyPromotion";
import MyMemberWithdrawal from "./components/myoffice/Member/MyMemberWithdrawal";
import UnderMemberInfo from "./components/myoffice/Member/UnderMemberInfo";
import MyFindId from "./components/myoffice/Member/MyFindId";
import MyFindPasswd from "./components/myoffice/Member/MyFindPasswd";
import invoice_my from "./components/invoice/invoice";
import Term from "./components/main/Term";
import modalViewPopup from "./components/modalViewPopup";
import MyFAQ from './components/myoffice/Board/MyFAQ';
import MyAcademy from './components/myoffice/Member/MyAcademy';

import addressBook from './components/addressBook';
import addressSearch from './components/shop/addressSearch';

import AsCart from './components/shop/AsCart';
import AsOrder from './components/shop/AsOrder';
import AutoShipAgreeText from './components/shop/AutoShipAgreeText';
import AutoShipGuideText from './components/shop/AutoShipGuideText';

import promotions from './components/myoffice/promotion';

const app = createApp({})

// Vue.component('example-component1', require('./components/ExampleComponent1.vue').default);
app.component('layout_top', LayoutTop)
app.component('layout_bottom', LayoutBottom)
app.component('layout_mobile_footer_menu', LayoutMobileFooterMenu)
app.component('layer_quick_menu', LayerQuick)

// app.component('main_page', MainPage)
app.component('visual_banners', VisualBanners)
// app.component('new_products', NewProducts)
app.component('product_list', ProductList)
app.component('product_item', ProductItem)
app.component('product_item_test', ProductItemTest)

app.component('cart', Cart)
app.component('direct_purchase', Direct)
app.component('order', Order)
app.component('order_summary', OrderSummary)
/*MYPAGE */
app.component('mypage', Mypage)
app.component('mypage_order_list', MypageOrderList)
app.component('mypage_order_view', MypageOrderView)
app.component('mypage_notice_list', MypageNoticeList)
app.component('mypage_board_view', MypageBoardView)
app.component('mypage_faq', MypageFAQ)
app.component('mypage_inquiry_list', MypageInquiryList)
app.component('mypage_inquiry_modify', MypageInquiryModify)
app.component('mypage_inquiry_view', MypageInquiryView)
app.component('mypage_academypage', MypageAcademyPage)
app.component('mypage_academy_view', MypageAcademyView)
app.component('memberinfo', MemberInfo)
app.component('memberfind', MemberFind)
app.component('userdel', UserDel)

app.component('my_academy', MyAcademy)

/*MYOFFICE */
app.component('myoffice_main', MyofficeMain)
app.component('notice_title', NotieTitle)
app.component('myoffice_nav', MyofficeNav)
app.component('myoffice_dash_board', MyofficeDashBoard)
app.component('my_rank_promotion', MyRankPromotion)
app.component('member_list', MyMember)
app.component('my_member_favorites', MyMemberFavorites)
app.component('my_member_leg_box', MyMemberLegBox)
app.component('my_member_leg_box_compress', MyMemberLegBoxCompress)
app.component('my_member_leg_line', MyMemberLegLine)
app.component('my_member_rank', MyMemberRank)
app.component('my_member_up_change', MyMemberUpChange)
app.component('my_person_info', MyPersonInfo)
app.component('my_person_info_modify', MyPersonInfoModify)
app.component('my_person_info_modify_center', MyPersonInfoModifyCenter)
app.component('my_benefit_search', MyBenefitSearch)
app.component('my_cp58', Mycp58)
app.component('my_benefit_detail_history', MyBenefitDetailHistory)
app.component('my_benefit_total_search', MyBenefitTotalSearch)
app.component('my_benefit_list', MyBenefitList)
app.component('my_benefit_trans_history', MyBenefitTranslHistory)
app.component('my_benefit_calendar', MyBenefitCalendar)
app.component('my_nenefit_analysis', MyBenefitAnalysis)
app.component('my_benefit_allowance_return', MyBenefitAllowanceReturn)
app.component('my_notice', MyNotice)
app.component('my_data', MyData)
app.component('board_view', BoardView)
app.component('my_inquriy', MyInQuriy)
app.component('my_inquriy_modify', MyInQuriyModify)
app.component('my_inquriy_view', MyInQuriyView)
app.component('PulseLoader', require('vue-spinner/src/PulseLoader.vue'))
app.component('order_list', MyOrderList)
app.component('order_view', MyOrderView)
app.component('my_order_legs', MyOrderLegs)


app.component('my_view_order', MyViewOrder)
app.component('my_order_down', MyOrderDown)
app.component('my_order_dir_down', MyOrderDirDown)
app.component('my_weekly_promotion', MyWeeklyPromotion)
app.component('my_foriner_upload', MyforinerUpload)
app.component('my_member_withdrawal', MyMemberWithdrawal)
app.component('under_member_info', UnderMemberInfo)
app.component('my_find_id', MyFindId)
app.component('my_find_passwd', MyFindPasswd)
app.component('invoice_my', invoice_my)

app.component('address_book', addressBook)
app.component('term', Term)
app.component('modal_view_popup', modalViewPopup)
app.component('address_search', addressSearch)
app.component('my_faq', MyFAQ)
app.component('idpwchk', idpwchk)
app.component('address_list', AddressList)
app.component('address_modify', AddressModify)

app.component('as_cart', AsCart)
app.component('as_order', AsOrder)
app.component('autoship_modify', AutoShipModify)
app.component('autoship_withdrawal', AutoShipWithdrawal)
app.component('heartmanager', HeartManager)
app.component('autoshipagreetext', AutoShipAgreeText)
app.component('autoshipguidetext', AutoShipGuideText)

app.component('promotions', promotions)


// app.use(router)  // vue 라우터 사용안함+

app.use(i18nVue)
app.use(alert)
app.use(common)

// vuex 추가 반영 add by hskim :: 2023-08-04 오전 9:31
app.use(store)
app.config.globalProperties.$store = store
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

app.mount('#app')
// const app = new Vue({
//     el: '#app',
// });
