<template>
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-slate-100 lg:bg-white min-h-[300] lg:min-h-[500]">
      <div class="max-w-8xl mx-auto py-4 px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-slate-100 lg:bg-white">
        <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 mt-3 mb-2">{{ title }}</h2>
        <div class="flex flex-wrap justify-start pt-2 text-neutral-500 text-sm lg:text-base">
          <div @click="list_url(category.seq_pdc)" v-for=" category in getCategoryPath "
               class="cursor-pointer border-r border-black text-center hover:text-blue-400 last:border-0 mb-1" :class="{ active :(parseInt(no) == parseInt(category.seq_pdc))}"><span class="px-3">{{ category.cate_name }}</span>
          </div>
        </div>
        <h2>{{ no_search_messsage }}</h2>
        <div v-if="products.length" class="mt-6 grid grid-cols-2 gap-x-1 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          <div v-for="product in products" :key="product.id" class="group relative bg-white border-black m-1.5 rounded-lg p-2 border-slate-900">
            <div class="w-full bg-gray-100 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
              <img :src="product.imageSrc" :alt="product.imageAlt" class="w-full object-center object-cover max-w-none	" />
              <div class="sold-out-box2" v-if="product.status == 2">
                <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
              </div>
              <div class="sold-out-box2" v-if="product.status == 3">
                <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
              </div>
              <div class="coming_box" v-if="product.status == 4">
                <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
              </div>
              <div class="badge_box" v-if="product.badge != ''">
                <img :src="product.badge" alt="sold out" />
              </div>
            </div>

            <div class="mt-4 justify-between ">
              <div class="h-12 lg:h-13 md:h-13 sm:h-12 ">
                <h3 class="text-gray-700 mb-2.5 text-sm lg:text-base sm:text-sm text-ellipsis overflow-hidden line-clamp-2 lg:h-13 md:h-13 h-11 font-light">
                  <a :href="product.href">
                    <span aria-hidden="true" class="absolute inset-0" style="bottom: 100px;" />
                    {{ product.name }}
                  </a>
                </h3>
              </div>
              <div v-if=" isLoggedIn " class="w-full mb-5 font-semibold">
                <p class="font-medium text-gray-900 lg:text-lg text-md">Harga GCOOPER : {{ product.price }}</p>
                <p class="mt-1 text-gray-500 lg:text-lg text-cyan-400 text-md">{{ product.bv }}</p>
              </div>
              <div v-else class="w-full mb-5 font-semibold">
                <p class="font-medium text-gray-400 lg:text-lg text-md">Retail Price : {{ product.con_price }}</p>
              </div>
            </div>
            <div v-if=" isLoggedIn && main != true" class="w-full mb-5 font-semibold">
              <div class="inline-flex items-center font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md">
                <button @click="decrement(product)" class="py-2 hover:text-gray-700 px-1.5 lg:px-3 md:px-2">
                  <svg class="text-blue-400" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.35"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                </button>
                <input type="number" name="qty" v-model="product.qty" @input="changeQty(product)" class="w-8 m-0 px-0 py-2 text-center border-0 focus:ring-transparent focus:outline-none rounded-md lg:px-2 lg:py-2 lg:w-12" id="custom-input-number">
                <button @click="increment(product)" class="py-2 hover:text-gray-700 px-1.5 lg:px-3 md:px-2">
                  <svg class="text-blue-400" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.35"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                </button>
              </div>
              <div v-if="product.status == 1 || product.status == 2"
                   class="inline-flex items-end align-top pl-1.5 float-right border-solid border border-gray-300 rounded-md flex items-center justify-between bg-white pl-0 w-10 lg:w-12"
                   style="height: 42px;">
                <a class="block text-center py-2 uppercase transition duration-200 flex w-full justify-evenly p-0 cursor-pointer"  v-on:click.prevent="addCart(product)">
                  <ShoppingCartIcon class="h-6 sm:h-8 w-5 lg:w-6 text-zinc-600" aria-hidden="true" />
                </a>
              </div>
            </div>

          </div>
        </div>
        <!--            <button class="text-l mx-auto font-extrabold tracking-tight text-gray-900">더보기</button>-->
      </div>
    </div>
  </section>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import Swal from 'sweetalert2'
import {ShoppingCartIcon} from '@heroicons/vue/outline'

export default {
  name: "ProductList",
components : {
  VueSimpleAlert,
    ShoppingCartIcon,
    Swal
},
  props: ['no','limit','keyword','main'],
  setup() {
    return {
      // products,
    }
  },
  data: () => ({
    cookName: 'pickUpPoup_20230802',
    i18n: window.i18n,
    title: '',
    products: [],
    getCategoryPath: [],
    isLoggedIn: false,
    star_check : window.Laravel.star_check,
    max_qty: 99,
    isPickUp: window.isPickUp,
    pickupCategoryIdx: window.pickupCategoryIdx,

  }),
  created() {

    if(this.no) {
      switch (this.no) {
        case 'new':
          // 신상품
          axios.post(process.env.MIX_BACK_URL + '/api/product/new', {
            bz_cd: window.Laravel.bz_cd,
            limit: this.limit
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}
          ).then(response => {
            this.title = i18n.shop.category.newproducts;
            this.getProductList(response.data);
          });
          break;
        case 'best':
          // 베스트
          axios.post(process.env.MIX_BACK_URL + '/api/product/best', {
            bz_cd: window.Laravel.bz_cd,
            limit: this.limit
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}
          ).then(response => {
            this.title = i18n.shop.category.bestsellers;
            this.getProductList(response.data);
          });
          break;
        case 'all':
          // 전체
          axios.post(process.env.MIX_BACK_URL + '/api/product/all', {
            bz_cd: window.Laravel.bz_cd
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}
          ).then(response => {
            this.title = i18n.shop.category.AllProducts;
            console.log(response.data);
            this.getProductList(response.data);
          });
          break;
        default :
          // 카테고리 메뉴
          // 연관 카테고리
            axios.post(process.env.MIX_BACK_URL + '/api/categoryPath', {
              bz_cd: window.Laravel.bz_cd,
              seq: this.no,
            }).then(response => {
              if (response.data.length) {
                this.title = response.data[0].parent;
                if(!this.limit) // 목록 수 제한이 없으면
                {
                  let refseq = response.data[0].refseq;
                  this.getCategoryPath = response.data;
                  this.getCategoryPath.unshift({
                    'parent': this.title,
                    'seq_pdc': refseq,
                    'regseq': refseq,
                    'cate_name': 'All Products'
                  });
                }
              }
            });


          if (!this.star_check && ([350, 351, 352, 353].includes(Number(this.no)))) {
            // if ((this.no == 336 || this.no == 337 || this.no == 338 || this.no == 339) && this.star_check == false) {
                VueSimpleAlert.alert("Star Mall is available only to members who have purchased more than 500BV.", "", "", { }).then((result) => {
                    if (result) {
                        location.replace("/");
                    }
                });
            // }else if ( [367].includes(Number(this.no)) ){
          } else if (!!this.isPickUp && [Number(this.pickupCategoryIdx)].includes(Number(this.no))) {


            // https://sweetalert2.github.io/#configuration

            if (this.get_cookie(this.cookName) === null) {

              Swal.fire({
                title: i18n.shop.pickup.pickupOnlyTitle,
                icon: 'info',
                html:
                  '<div style="text-align:left;font-size:0.99em;">' + i18n.shop.pickup.pickupOnlyInfo + '</div>',
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: true,
                confirmButtonText:
                  'OK',
                confirmButtonAriaLabel: 'closed',
                cancelButtonText:
                i18n.shop.popup.noOpen,
                cancelButtonAriaLabel: i18n.shop.popup.noOpen
              }).then((result) => {

                console.group("====== aaa ======")
                console.dir(result);
                console.groupEnd("====== aaa ======")

                if (result.isConfirmed) {
                }
                // close
                else if (result.isDismissed) {
                  // 오늘하루그만보기..
                  if (result.dismiss === 'cancel') {
                    this.set_close_cookie(this.cookName);
                    // } else if (result.dismiss === 'close') {
                  }
                }
              })
            }

            // VueSimpleAlert.confirm(
            //   "Products for on-site pickup cannot be added to the cart along with shipping items. Only on-site pickup items can be ordered and paid for",
            //   "",
            //   "info",
            //   {}).then((result) => {
            //   if (result) {
            //   }
            // });
            }

            // 제품 리스트업
          axios.post(process.env.MIX_BACK_URL + '/api/product/cate', {
            bz_cd: window.Laravel.bz_cd,
            cate: this.no,
            isLogin : window.Laravel.isLoggedin,
            rankCd : window.Laravel.rank_cd,
            loginAccessCategory : window.Laravel.loginAccessCategory,
            limit : (this.main === true && this.limit > 0 ) ? this.limit : null,
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}
          ).then(response => {
            // console.log(response.data);

            // 프로모션 페이지 추가 add by hskim :: 2023-08-11 오전 11:42
            if ( this.main === true && Number(this.no) === 368){
              this.title = i18n.shop.category.Promotion;
            }

            this.getProductList(response.data);
          });

      }
    }
    if(this.keyword){
      // 전체
      axios.post(process.env.MIX_BACK_URL+'/api/product/search', {
        bz_cd: window.Laravel.bz_cd,
        keyword: this.keyword,
        isLogin : window.Laravel.isLoggedin,
        rankCd : window.Laravel.rank_cd,
        loginAccessCategory : window.Laravel.loginAccessCategory
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
        this.title = "\""+this.keyword + "\" search Products";
        // console.log(response.data);
        this.getProductList(response.data);
      });
    }

  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{

    get_cookie(cookieName) {
      console.log(' === get_cookie ===');
      return $cookies.get(cookieName);
    },
    set_close_cookie(cookieName) {
      console.log(' === set_close_cookie ===');
      $cookies.set(cookieName, "1", "1d");
    },
    getProductList($list) {
      if($list.length < 1){
        this.no_search_messsage = "There are no results";
      }
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
              if((value.seq_pdc == 336 || value.seq_pdc == 337 || value.seq_pdc == 338 || value.seq_pdc == 339)  ){

              }else{
                  this.products.push({
                      id: value.seq_pd,
                      name: value.pdt_name,
                      href: '/product/item/' + value.seq_pd,
                      imageSrc: 'https://cdn.gcoop.me/public' + value.images[1]?.path + '/' + value.images[1]?.filename,
                      imageAlt: value.cate_name + '>' + value.pdt_name,
                      // price: numeral(value.prices.amt).format('$0,0.00'),
                      price: 'Rp ' + numeral(value.prices.amt).format('0,0'),
                      con_price: 'Rp ' + numeral(value.prices.set1_amt).format('0,0'),
                      bv: numeral(value.prices.pv).format('0,0.0') + ' BV',
                      qty : 1,
                      status : value.status,
                      badge : value.badge != "" ? "https://cdn.gcoop.me/"+value.badges.image_url : "",
                  });

              }
      });
    },
    list_url(link){
      location.href="/product/list/"+link;
    },

    // 장바구니 담긴 상품수
    getCountCartItems() {
      axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
        bz_cd: window.Laravel.bz_cd
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
        .then(response => {
          this.$store.commit("setCartCount",response.data );
        });
    },

    addCart(product){
      axios.post(process.env.MIX_BACK_URL+'/api/add_cart',{
        item: product.id,
        qty: product.qty,
        client_ip: window.Laravel.clientIp,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          if(response.data.msg){
            if(response.data.result == 'Y') {

              VueSimpleAlert.alert("", "", "", {
                background: 'transparent',
                timer: 1000,
                imageHeight: 300,
                imageUrl: '/images/putin_cart_icon_us.png',
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: false
              }).then((result) => {
                if (result) {

                  this.getCountCartItems();
                  // axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
                  //   bz_cd: window.Laravel.bz_cd
                  // }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                  //   .then(response => {
                  //     this.countCartItems = response.data;
                  //     this.$store.commit("setCartCount", this.countCartItems);
                  //   });

                }
              });
            } else if (response.data.result == 'D') {

              Swal.fire({
                title: i18n.shop.pickup.pickupOnlyTitle,
                icon: 'info',
                html:
                response.data.msg,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: true,
                confirmButtonText:
                  'OK',
                confirmButtonAriaLabel: 'closed',
                cancelButtonText:
                  'CANCEL',
                cancelButtonAriaLabel: i18n.shop.popup.noOpen
              }).then((result) => {

                if (result.isConfirmed) {
                  this.loading = true;

                  // 기존 카테고리 삭제 후,
                  axios.post(window.Laravel.back_url + '/api/registerByChangingCart',
                    {
                      item: product.id,
                      qty: product.qty,
                      client_ip: window.Laravel.clientIp,
                    }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}
                  ).then(response => {
                    if (response.data.result == 'Y') {
                      VueSimpleAlert.alert("", "", "", {
                        background: 'transparent',
                        timer: 1000,
                        imageHeight: 300,
                        imageUrl: '/images/putin_cart_icon_us.png',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false
                      }).then((result) => {
                        if (result) {
                }
                      }).catch((err) => {
                        // throw new Error(`Problem handling something: ${err}.`);
              });

                      this.getCountCartItems

                      // axios.post(process.env.MIX_BACK_URL + '/api/cartCount', {
                      //   bz_cd: window.Laravel.bz_cd
                      // }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                      //   .then(response => {
                      //     this.countCartItems = response.data;
                      //     this.$store.commit("setCartCount", this.countCartItems);
                      //   });

                      this.loading = false;
                    }
                  });
                }
              })

            }else{
              VueSimpleAlert.alert(response.data.msg, "", "", { }).then((result) => {
                if (result) {
                  //location.replace("/MyforinerUpload");
                }
              });

              //alert(response.data.msg);
            }
          }
          if(response.data.result == 'Y'){
            //window.location.reload()
          }
        });
    },
    decrement(product){
        product.qty = this.formatPrice(product.qty,0);

        if(product.qty > 1) {
            product.qty--;
        }
    },
    increment(product){
        product.qty = this.formatPrice(product.qty,0);
        if(product.qty < this.max_qty) {
            product.qty++;
          }
    },
      changeQty(product){
          product.qty = this.formatPrice(product.qty,0);
          if(product.qty < 1) {
              this.count = 1;
          }
          if(product.qty > this.max_qty) {
              product.qty = this.max_qty;
          }
      },
      formatPrice(value,digit) {
          // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
          digit = 0;
          value = isNaN(value) ? 0 : value;
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
  }
}

$(window).scroll(function(e){
  if($(".container").length == 1){
    if($(document).scrollTop() > 200 ){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","200px");
    }
  }else if($(".container").length == 0){
    if($(document).scrollTop() > 155){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","155px");
    }
  }
});

</script>
<style>
.active {
  color: rgb(96, 165, 250);
  font-weight: bold
}

.badge_box {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.badge_box > img {
  width: 25% !important;
  top: 8px;
  right: 8px;
  position: absolute;
  z-index: 1;
  background: transparent !important;
}

.coming_box {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.coming_box > img {
  width: 25% !important;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  background: transparent !important;
}

.sold-out-box2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.sold-out-box2 > img {
  width: 100% !important;
  top: 0px;
  right: 0px;
  position: absolute;
  z-index: 1;
  background: transparent !important;
}

.quick-menu {
  position: absolute;
  top: 200px;
}

#swal2-content {
  min-height: 100px;
  //text-align: left;
  font-size: 1.2em;
  //text-align : center;
}

.swal2-styled.swal2-confirm {
  min-width: 174px;
}

.swal2-styled.swal2-cancel {
  background-color: #cecece;
  min-width: 174px;
}

.swal2-html-container {
  font-size: 0.99em;
}


</style>
